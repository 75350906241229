import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { AuthService } from 'app/core/auth.service';
import { UserService } from 'app/core/user.service';
import { GroupRelation } from 'app/groups/models/group-relation';
import { GroupType } from 'app/groups/models/group-type';
import { GroupPrivacy } from 'app/groups/models/groupPrivacy';
import { MembershipService } from 'app/shared-services/membership/membership.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { Observable, Subject } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';
import { Event } from '../../../groups/events/models/event';
import { EventService } from '../../../core/event.service';
@Component({
  selector: 'app-event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventCardComponent implements OnInit, OnChanges {
  @Input() event: Event;
  @Input() showDate = true;
  @Input() showRequests = false;
  @Input() relation: GroupRelation;
  @Input() showPin = false;
  @Input() pinnedInput: boolean;
  @Input() showMemberCount = true;
  @Input() showDuplicate = true;
  owner$: Observable<MondoUser>;
  requestingUsersCount$: Observable<number>;
  ownerGroup$: Observable<Event>;
  duplicate = false;
  pinDebouncer$: Observable<string>;
  pinned: boolean;

  private pinDebouncer = new Subject<string>();

  constructor(
    private userService: UserService,
    private eventService: EventService,
    private authService: AuthService,
    private membershipService: MembershipService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    if (this.event) {
      if (
        this.showRequests ||
        (this.authService.getCurrentUser() &&
          this.authService.getCurrentUser().uid === this.event.ownerId)
      ) {
        this.requestingUsersCount$ =
          this.membershipService.getNumberOfRequestingUsersFromGroup$(
            this.event.key,
            GroupType.Events
          );
      }
      this.owner$ = this.userService.getUserByUid$(this.event.ownerId);
      if (this.event.ownerGroupId) {
        this.ownerGroup$ = this.eventService.getEvent$(this.event.ownerGroupId);
      }
    }

    this.pinDebouncer$ = this.pinDebouncer.pipe(
      debounceTime(2000),
      tap((eventKey) => {
        if (this.pinnedInput === false && eventKey === null) {
          return;
        } else {
          this.eventService.setPinnedEventKey(eventKey);
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pinnedInput) {
      this.pinned = changes.pinnedInput.currentValue;
      this.cdr.markForCheck();
    }
  }

  get isAdmin() {
    return this.authService.isAdmin;
  }

  public canPin(): boolean {
    return (
      this.isAdmin &&
      (this.event.privacy === GroupPrivacy.Public ||
        this.event.privacy === GroupPrivacy.OpenPrivate)
    );
  }

  pinEvent(e) {
    e.stopPropagation();
    if (this.canPin()) {
      if (this.pinned) {
        this.pinned = false;
        this.pinDebouncer.next(null);
      } else {
        this.pinned = true;
        this.pinDebouncer.next(this.event.key);
      }
    }
  }

  duplicateEvent(e, event: Event) {
    e.stopPropagation();
    if (!this.duplicate) {
      this.duplicate = true;
      event.permissions.hideFromSearch = false;
      return this.eventService.duplicateEvent(event);
    }
  }
}
