import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { routerAnimation } from './_animations/routerAnimation';
import { AuthService, UserStatus } from './core/auth.service';
import { DeviceService } from './core/device.service';
import { PresenceService } from './core/presence.service';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PushNotificationService } from './notifications-new/services/push-notification.service';
import { JwksValidationHandler, OAuthService } from 'angular-oauth2-oidc';
import { SsoAuthService } from './core/sso-auth.service';
import { authConfig } from './core/auth-config';
import { acaConfig } from 'aca-config';
import { ConfigService } from './core/config.service';
import { AppUpdateService } from './core/app-update.service';
import { environment } from 'environments/environment';
import { hardcodedValues } from 'hardcodedValues';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    routerAnimation,
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('500ms', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('500ms', style({ transform: 'translateY(100%)', opacity: 0 })),
      ]),
    ]),
  ],
  // changeDetection: ChangeDetectionStrategy.OnPush, // not ready yet
})
export class AppComponent implements OnInit {
  isReady = false;
  loggedIn = false;
  userStatus$: Observable<UserStatus>;
  public ready$: Observable<boolean>;
  public isAuthed$: Observable<boolean>;
  private isAuthedWithFirebase = false;

  constructor(
    private authService: AuthService,
    private ssoAuthService: SsoAuthService,
    public presence: PresenceService,
    public deviceService: DeviceService,
    private msgService: PushNotificationService,
    private oauthService: OAuthService,
    private configService: ConfigService,
    private appUpdateService: AppUpdateService
  ) {}

  ngOnInit() {
    this.setLanguageForSite();
    if (!this.marketingCookies) {
      this.importJSCookieScript();
    }
    this.setMetaContent();
    if (
      hardcodedValues.enableCountdownForEventDeadline ||
      hardcodedValues.groupKanbanEnabled
    ) {
      this.importCountdownFlipDown();
      this.importConfetti();
    }
    this.importMarked();
    if (hardcodedValues.chatbotBuilderApiHost) {
      this.importChatGPT();
    }
    if (environment.production && acaConfig['umamiKey']) {
      this.importUmamiScript();
    }
    if (acaConfig['TwitterListHref']) {
      this.importTwitterScript();
    }
    window.addEventListener('resize', this.appHeight);
    window.addEventListener('orientationchange', this.appHeight);
    this.appHeight();
    this.IframeHideNavbar();
    if (acaConfig.SSOEnabled) {
      this.configure();
    }
    this.msgService.receiveMessage();
    this.authService.afstateHandler().subscribe();
    this.userStatus$ = this.authService.getUserStatus$().pipe(
      tap((userStatus) => {
        if (userStatus) {
          this.isReady = !userStatus.waitingForStatus;
          this.loggedIn = userStatus.loggedIn && this.isReady;
          if (!userStatus.dbAccess) {
            alert('System is under maintenance');
          }
        }
      })
    );
    this.isAuthed$ = this.ssoAuthService.isAuthenticated$.pipe(
      tap(async (isAuthed) => {
        if (
          isAuthed &&
          this.ssoAuthService.accessToken &&
          !this.isAuthedWithFirebase
        ) {
          this.isReady = false;
          this.ssoAuthService
            .signinWithFirebase(this.ssoAuthService.accessToken)
            .then((res) => {
              // console.log('sign in done!', res);
            })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              // console.log(
              //   'do something Extra?!?',
              //   this.ssoAuthService.accessToken
              // );
            });
          this.isAuthedWithFirebase = true;
        }
      })
    );
  }

  IframeHideNavbar() {
    const doc = document.documentElement;
    if (window.top !== window.self) {
      // if (this.getParamValue('navbar') === 'no-navbar') {
      this.hideNavbar();
    } else {
      doc.style.setProperty('--chat-bot-display', `block`, 'important');
    }
  }

  hideNavbar() {
    const doc = document.documentElement;
    doc.style.setProperty('--navbar-height', `0px`);
    doc.style.setProperty('--navbar-height-new', `0px`);
    doc.style.setProperty('--navbar-display', `none`, 'important');
    doc.style.setProperty('--chat-bot-display', `none`, 'important');
  }

  getParamValue(paramName) {
    var url = window.location.search.substring(1); //get rid of "?" in querystring
    var qArray = url.split('&'); //get key-value pairs
    for (var i = 0; i < qArray.length; i++) {
      var pArr = qArray[i].split('='); //split key and value
      if (pArr[0] == paramName) return pArr[1]; //return value
    }
  }

  appHeight() {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight - 2}px`);
    doc.style.setProperty('--vh', `${(window.innerHeight - 2) * 0.01}px`);
    // console.log(window.innerHeight - 1, (window.innerHeight - 1) * 0.01);
  }

  get marketingCookies() {
    return acaConfig['marketingCookies'] ? true : false;
  }

  private configure() {
    this.oauthService.configure(authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
    // this.oauthService.loadDiscoveryDocumentAndLogin();
  }

  private setTitleForSite() {
    const metaTag = document.createElement('title');
    metaTag.text = acaConfig.academondo;
    const s = document.getElementsByTagName('meta')[0];
    s.parentNode.insertBefore(metaTag, s);

    const regularMetaTag = document.createElement('meta');
    regularMetaTag.name = 'title';
    regularMetaTag.content = acaConfig.academondo;
    const s1 = document.getElementsByTagName('meta')[1];
    s1.parentNode.insertBefore(regularMetaTag, s1);

    const appleMetaTag = document.createElement('meta');
    appleMetaTag.name = 'apple-mobile-web-app-title';
    appleMetaTag.content = acaConfig.academondo;
    const s2 = document.getElementsByTagName('meta')[2];
    s2.parentNode.insertBefore(appleMetaTag, s2);
  }

  private setThemeColorForSite() {
    const styles = getComputedStyle(document.documentElement);
    const themeColor = styles.getPropertyValue('--primary');

    const metaTag = document.createElement('meta');
    metaTag.name = 'theme-color';
    metaTag.content = themeColor;
    const s = document.getElementsByTagName('meta')[0];
    s.parentNode.insertBefore(metaTag, s);

    const appleMetaTag = document.createElement('meta');
    appleMetaTag.name = 'apple-mobile-web-app-status-bar-style';
    appleMetaTag.content = themeColor;
    const s1 = document.getElementsByTagName('meta')[1];
    s1.parentNode.insertBefore(appleMetaTag, s1);
  }

  private setLanguageForSite() {
    document.documentElement.setAttribute('lang', acaConfig.language);
  }

  private importJSCookieScript() {
    const script = document.createElement('script');
    script.defer = true;
    script.type = 'text/javascript';
    script.src = `https://cdn.jsdelivr.net/npm/js-cookie@3.0.5/dist/js.cookie.min.js`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }

  private importTwitterScript() {
    const script = document.createElement('script');
    script.crossOrigin = 'anonymous';
    script.defer = true;
    script.type = 'text/javascript';
    script.src = 'https://platform.twitter.com/widgets.js';
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }

  private importUmamiScript() {
    const script = document.createElement('script');
    script.defer = true;
    script.type = 'text/javascript';
    script.src = `https://cloud.umami.is/script.js`;
    script.setAttribute('data-website-id', acaConfig['umamiKey']);
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }

  private importCountdownFlipDown() {
    const script = document.createElement('script');
    script.defer = true;
    script.type = 'text/javascript';
    script.src = `https://cdn.jsdelivr.net/npm/flipdown@0.3.2/src/flipdown.min.js`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);

    var link = document.createElement('link');
    link.rel = 'stylesheet';
    link.href = `https://cdn.jsdelivr.net/npm/flipdown@0.3.2/dist/flipdown.min.css`;
    const s1 = document.getElementsByTagName('script')[0];
    s1.parentNode.insertBefore(link, s1);
  }

  private importMarked() {
    const script = document.createElement('script');
    script.defer = true;
    script.type = 'text/javascript';
    script.src = `https://cdn.jsdelivr.net/npm/marked/marked.min.js`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }
  private importChatGPT() {
    const script = document.createElement('script');
    script.type = 'module';
    script.textContent = `
      import Chatbot from 'https://cdn.jsdelivr.net/gh/agerskov/chatbot1.6/dist/web.js';
    `;
    // script.textContent = `import Chatbot from 'https://cdn.jsdelivr.net/gh/FlowiseAI/FlowiseChatEmbed/dist/web.js';`;
    // script.textContent = `
    // import Chatbot from 'https://cdn.jsdelivr.net/npm/flowise-embed@1.6/dist/web.js;
    // `;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }

  private importConfetti() {
    const script = document.createElement('script');
    script.defer = true;
    script.type = 'text/javascript';
    script.src = `https://cdn.jsdelivr.net/npm/canvas-confetti@1.5.1/dist/confetti.browser.min.js`;
    const s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(script, s);
  }

  private setMetaContent() {
    this.setViewportMetaContent();
    this.setThemeColorForSite();
    this.setTitleForSite();
  }

  private setViewportMetaContent() {
    const isPWA = window.matchMedia('(display-mode: standalone)').matches;

    const viewportMeta = document.createElement('meta');
    viewportMeta.name = 'viewport';
    viewportMeta.content = 'width=device-width, initial-scale=1';
    if (isPWA) {
      viewportMeta.content = viewportMeta.content + ', user-scalable=no';
    }
    if (this.isSafari()) {
      viewportMeta.content =
        'width=device-width, initial-scale=1, maximum-scale=1';
      viewportMeta.content = viewportMeta.content + ', maximum-scale=1';
    }
    const s = document.getElementsByTagName('meta')[0];
    s.parentNode.insertBefore(viewportMeta, s);
  }

  private isSafari() {
    const ua = navigator.userAgent.toLowerCase();
    return (
      (ua.indexOf('iphone') !== -1 || ua.indexOf('ipad') !== -1) &&
      ua.indexOf('safari') !== -1 &&
      ua.indexOf('chrome') === -1
    );
  }
}
