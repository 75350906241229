import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import { MondoRoutes, RoutingModel } from 'app/app.routing-model';
import { AccountType } from 'app/shared/consts/accountType';
import { UserStatus } from './auth.service';

@Injectable()
export class RoutingService {
  private readonly allowedRoutes: string[] = [
    RoutingModel.aboutUs.route,
    RoutingModel.aboutPlatform.route,
    RoutingModel.aboutSkuffen.route,
    RoutingModel.aboutNad.route,
    RoutingModel.cookies.route,
    RoutingModel.emailActionManager.path,
    RoutingModel.link.route,
    RoutingModel.job.route,
    RoutingModel.newUser.route,
    RoutingModel.map.route,
    RoutingModel.landingPage.route,
    RoutingModel.tutorials.route,
    RoutingModel.scienceMatch.route,
    RoutingModel.communities.route,
    RoutingModel.events.route,
    RoutingModel.ideas.route,
    RoutingModel.gdpr.route,
    RoutingModel.wp.route,
    RoutingModel.wp2.route,
    RoutingModel.multiwp.route,
    RoutingModel.wptools.route,
    RoutingModel.ai.route,
  ];

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  public navigateToRoute(route: string, data?: any[]): Promise<boolean> {
    return this.navigateToUrlWithDataArray(
      RoutingModel[route] ? RoutingModel[route].route : route,
      data
    );
  }

  public navigateToUrlWithDataArray(url: any, data?: any[]): Promise<boolean> {
    if (url.indexOf('?') > 0) {
      return this.router.navigateByUrl(url);
    } else {
      if (data) {
        return this.router.navigate([url].concat(data));
      } else {
        return this.router.navigate([url]);
      }
    }
  }

  public navigateToUrlWithDataArrayWithNoReuse(
    url: any,
    data?: any[]
  ): Promise<boolean> {
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    if (url.indexOf('?') > 0) {
      return this.router.navigateByUrl(url).finally(() => {
        this.router.routeReuseStrategy.shouldReuseRoute = prev;
        this.router.onSameUrlNavigation = prevOSN;
      });
    } else {
      if (data) {
        return this.router.navigate([url].concat(data)).finally(() => {
          this.router.routeReuseStrategy.shouldReuseRoute = prev;
          this.router.onSameUrlNavigation = prevOSN;
        });
      } else {
        return this.router.navigate([url]).finally(() => {
          this.router.routeReuseStrategy.shouldReuseRoute = prev;
          this.router.onSameUrlNavigation = prevOSN;
        });
      }
    }
  }

  public navigateWithNoReuse(
    url: any[],
    data?: NavigationExtras
  ): Promise<boolean> {
    const prev = this.router.routeReuseStrategy.shouldReuseRoute;
    const prevOSN = this.router.onSameUrlNavigation;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';

    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    if (url.indexOf('?') > 0) {
      return this.router.navigate(url).finally(() => {
        this.router.routeReuseStrategy.shouldReuseRoute = prev;
        this.router.onSameUrlNavigation = prevOSN;
      });
    } else {
      if (data) {
        return this.router.navigate(url, data).finally(() => {
          this.router.routeReuseStrategy.shouldReuseRoute = prev;
          this.router.onSameUrlNavigation = prevOSN;
        });
      } else {
        return this.router.navigate(url).finally(() => {
          this.router.routeReuseStrategy.shouldReuseRoute = prev;
          this.router.onSameUrlNavigation = prevOSN;
        });
      }
    }
  }

  public navigateToUrlWithDataObject(
    url: string,
    data?: Object
  ): Promise<boolean> {
    if (data) {
      return this.router.navigate([url, data], {
        relativeTo: this.activatedRoute,
      });
    } else {
      return this.router.navigate([url], { relativeTo: this.activatedRoute });
    }
  }

  public setQueryParams(queryParams: any) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: queryParams,
      queryParamsHandling: 'merge',
    });
  }

  public navigateToRoot(): Promise<boolean> {
    return this.router.navigate([MondoRoutes.login]);
  }

  public getCurrentRoute(): string {
    return this.router.url;
  }

  public isAllowedUrlForAll(): boolean {
    const url = this.getCurrentRoute();
    return this.allowedRoutes.some(
      (allowedUrl: string) =>
        allowedUrl !== '' && decodeURI(url).includes(allowedUrl)
    );
  }

  public navigateToUserProfile(userType: AccountType): Promise<boolean> {
    switch (userType) {
      case AccountType.scientist:
      case AccountType.medLabTech:
      case AccountType.labTech:
      case AccountType.ssoAuthedUser:
        return this.navigateToRoute(RoutingModel.cvBuilder.route);
      case AccountType.industry:
      case AccountType.university:
        return this.navigateToRoute(RoutingModel.siteBuilder.route);
      default:
        return this.navigateToRoot();
    }
  }

  public ifNoJobNavigation(status: UserStatus): Promise<boolean> {
    if (status.loggedIn && status.user) {
      switch (status.user.type) {
        case AccountType.scientist:
        case AccountType.medLabTech:
        case AccountType.labTech:
        case AccountType.ssoAuthedUser:
          return status.user.publishedCv
            ? this.navigateToRoute(RoutingModel.dashboard.route)
            : this.navigateToRoute(RoutingModel.cvBuilder.route);
        // return status.user.publishedCv ? this.navigateToRoute(RoutingModel.networks) : this.navigateToRoute(RoutingModel.cvBuilder);
        case AccountType.industry:
        case AccountType.university:
          return status.user['company'] && status.user['company'].uid
            ? this.navigateToRoute(RoutingModel.dashboard.route)
            : this.navigateToRoute(RoutingModel.home.route);
        // return status.user['company'] && status.user['company'].uid ? this.navigateToRoute(RoutingModel.home) : this.navigateToRoute(RoutingModel.networks);
        default:
          return this.navigateToRoot();
      }
    } else {
      return this.navigateToRoot();
    }
  }
  public navigateToUserDefault(status: UserStatus): Promise<boolean> {
    if (status.loggedIn && status.user) {
      switch (status.user.type) {
        case AccountType.scientist:
        case AccountType.medLabTech:
        case AccountType.labTech:
        case AccountType.ssoAuthedUser:
          return status.user.publishedCv
            ? this.navigateToRoute(RoutingModel.dashboard.route)
            : this.navigateToRoute(RoutingModel.cvBuilder.route);
        // return status.user.publishedCv ? this.navigateToRoute(RoutingModel.networks) : this.navigateToRoute(RoutingModel.cvBuilder);
        // return status.user.publishedCv ? this.navigateToRoute(RoutingModel.search) : this.navigateToRoute(RoutingModel.cvBuilder);
        case AccountType.industry:
        case AccountType.university:
          return status.user['company'] && status.user['company'].uid
            ? this.navigateToRoute(RoutingModel.dashboard.route)
            : this.navigateToRoute(RoutingModel.siteBuilder.route);
        // return status.user['company'] && status.user['company'].uid ? this.navigateToRoute(RoutingModel.networks) : this.navigateToRoute(RoutingModel.home);
        // return status.user['company'] && status.user['company'].uid ? this.navigateToRoute(RoutingModel.search) : this.navigateToRoute(RoutingModel.home);
        default:
          return this.navigateToRoot();
      }
    } else {
      return this.navigateToRoot();
    }
  }

  public goToSignUp(accountType: AccountType) {
    this.navigateToUrlWithDataArray(RoutingModel.signup.route, [accountType]);
  }

  public goToAbout() {
    this.navigateToUrlWithDataObject(RoutingModel.aboutUs.route);
  }

  public goToLogin() {
    this.navigateToUrlWithDataObject(RoutingModel.login.route);
  }

  public navigateWithState(url, data?: any[], stateParam?: object) {
    const path = this.makeUrl(url, data);
    if (stateParam) {
      this.router.navigate(path, stateParam);
    } else {
      this.router.navigate(path);
    }
  }

  private makeUrl(url, data?: any[]) {
    return data
      ? [RoutingModel[url].route].concat(data)
      : RoutingModel[url].route;
  }
}
