import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { IOSInstallInstructionsDialogComponent } from 'app/shared-ui/iosinstall-instructions-dialog/iosinstall-instructions-dialog.component';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AppInstallService {
  private deferredPrompt;
  private showPrompt = false;
  constructor(private authService: AuthService, private dialog: MatDialog) {
    if (this.isInstalled()) {
      return;
    } else {
      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault();
        this.deferredPrompt = event;
        this.authService
          .getCurrentUser$()
          .pipe(
            map((user: MondoUser) => {
              if (user && authService.currentUserhasPublicProfile) {
                this.showPrompt = true;
              }
              return [];
            })
          )
          .subscribe();
      });
    }
  }

  isIos() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  isInstalled() {
    return (
      window.matchMedia('(display-mode: standalone)').matches ||
      ('standalone' in window.navigator && window.navigator['standalone'])
    );
  }
  get showInstallPrompt() {
    if (!environment.production) {
      return false;
    }
    if (
      this.isIos() &&
      !this.isInstalled() &&
      this.authService.currentUserhasPublicProfile
    ) {
      return true;
    } else {
      return this.showPrompt;
    }
  }

  InstallPWA() {
    if (this.isIos()) {
      this.dialog.open(IOSInstallInstructionsDialogComponent, {
        width: '500px',
      });
    }

    if (this.deferredPrompt) {
      // Show the install prompt and handle the user's response
      this.deferredPrompt.prompt();
      this.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User installed the PWA');

          // Get the user's token for push notifications
          // this.afMessaging.getToken.subscribe((token) => {
          //   console.log('User token for push notifications:', token);
          // });
        } else {
          console.log('User declined to install the PWA');
        }

        // Reset the deferredPrompt variable to null since the prompt won't be shown again.
        this.deferredPrompt = null;
      });
    }
  }

  declineInstall() {
    // Hide the install prompt
    this.deferredPrompt = null;
  }
}
