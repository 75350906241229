import { Group, IGroup } from '../../models/group';
import { GroupType } from '../../models/group-type';
import { GroupPermissions } from '../../models/groupPermissions';
import { GroupPrivacy } from '../../models/groupPrivacy';
import { GroupTags } from '../../models/groupTags';
import { MondoLocation } from '../../../stepper/job/model/mondoLocation';
import { OnlineFormat } from './onlineFormat';
import {
  JsonTimePeriod,
  TimePeriod,
} from '../../../stepper/job/model/timePeriod';
import { isNumber } from '../../../shared/common/acaLodash';
import { Upload } from '../../../../../src/app/shared/models';

export class Event implements IEvent<Date>, Group {
  public readonly groupType = GroupType.Events;
  public static fromJson({
    key,
    ownerId,
    name,
    attendingCount,
    declinedCount,
    noReplyCount,
    interestedCount,
    maybeCount,
    coverUrl,
    description,
    isCanceled,
    isOnline,
    onlineFormat,
    discountCode,
    website,
    location,
    created,
    publicityDate,
    lastUpdate,
    logoUrl,
    maxAttendees,
    ownerGroupType,
    permissions,
    forumId,
    privacy,
    membersCount,
    tags,
    uploads,
    wantedDaysOfExperience0,
    wantedDaysOfExperience1,
    wantedDaysOfExperience2,
    wantedDaysOfExperience3,
    wantedDaysOfExperience4,
    wantedDaysOfExperience5,
    videoUrl,
    ownerGroupId,
    meetingLink,
    signupLink,
    eventDeadline,
    eventPeriod,
  }: IEvent<number>): Event {
    return new Event(
      key,
      ownerId,
      name,
      attendingCount,
      declinedCount,
      noReplyCount,
      interestedCount,
      maybeCount,
      coverUrl,
      description,
      isCanceled,
      isOnline,
      onlineFormat,
      discountCode,
      website,
      location,
      created ? new Date(created) : null,
      publicityDate ? new Date(publicityDate) : null,
      lastUpdate ? new Date(lastUpdate) : null,
      logoUrl,
      maxAttendees,
      ownerGroupType,
      ownerGroupId,
      permissions,
      forumId,
      privacy,
      membersCount,
      tags,
      uploads ? uploads : [],
      wantedDaysOfExperience0,
      wantedDaysOfExperience1,
      wantedDaysOfExperience2,
      wantedDaysOfExperience3,
      wantedDaysOfExperience4,
      wantedDaysOfExperience5,
      videoUrl,
      meetingLink,
      signupLink,
      eventDeadline ? new Date(eventDeadline) : null,
      TimePeriod.fromJson(eventPeriod)
    );
  }

  public static toJson(event: IEvent<Date>): IEvent<number> | any {
    return {
      key: event.key,
      ownerId: event.ownerId,
      name: event.name,
      attendingCount: isNumber(event.attendingCount)
        ? event.attendingCount
        : null,
      declinedCount: isNumber(event.declinedCount) ? event.declinedCount : null,
      noReplyCount: isNumber(event.noReplyCount) ? event.noReplyCount : null,
      interestedCount: isNumber(event.interestedCount)
        ? event.interestedCount
        : null,
      maybeCount: isNumber(event.maybeCount) ? event.maybeCount : null,
      coverUrl: event.coverUrl || null,
      description: event.description || null,
      isCanceled: event.isCanceled || false,
      isOnline: event.isOnline || false,
      onlineFormat: event.onlineFormat || null,
      discountCode: event.discountCode || null,
      website: event.website || null,
      location: event.location,
      created: event.created ? event.created.getTime() : null,
      publicityDate: event.publicityDate ? event.publicityDate.getTime() : null,
      lastUpdate: event.lastUpdate ? event.lastUpdate.getTime() : null,
      logoUrl: event.logoUrl || null,
      maxAttendees: event.maxAttendees || null,
      ownerGroupType: event.ownerGroupType || null,
      permissions: GroupPermissions.toJson(event.permissions),
      forumId: event.forumId,
      privacy: event.privacy,
      ownerGroupId: event.ownerGroupId || null,
      tags: event.tags ? GroupTags.toJson(event.tags) : null,
      uploads: event.uploads ? event.uploads : [],
      wantedDaysOfExperience0: event.wantedDaysOfExperience0
        ? event.wantedDaysOfExperience0
        : 0,
      wantedDaysOfExperience1: event.wantedDaysOfExperience1
        ? event.wantedDaysOfExperience1
        : 0,
      wantedDaysOfExperience2: event.wantedDaysOfExperience2
        ? event.wantedDaysOfExperience2
        : 0,
      wantedDaysOfExperience3: event.wantedDaysOfExperience3
        ? event.wantedDaysOfExperience3
        : 0,
      wantedDaysOfExperience4: event.wantedDaysOfExperience4
        ? event.wantedDaysOfExperience4
        : 0,
      wantedDaysOfExperience5: event.wantedDaysOfExperience5
        ? event.wantedDaysOfExperience5
        : 0,
      videoUrl: event.videoUrl || null,
      groupType: GroupType.Events,
      meetingLink: event.meetingLink || null,
      signupLink: event.signupLink || null,
      eventDeadline: event.eventDeadline ? event.eventDeadline.getTime() : null,
      eventPeriod: TimePeriod.toJson(event.eventPeriod),
    };
  }

  constructor(
    public key: string,
    public ownerId: string,
    public name: string,
    public attendingCount: number,
    public declinedCount: number,
    public noReplyCount: number,
    public interestedCount: number,
    public maybeCount: number,
    public coverUrl = '',
    public description: string,
    public isCanceled: boolean,
    public isOnline: boolean,
    public onlineFormat: OnlineFormat,
    public discountCode: string,
    public website: string,
    public location: MondoLocation,
    public created: Date,
    public publicityDate: Date,
    public lastUpdate: Date,
    public logoUrl = '',
    public maxAttendees: number,
    public ownerGroupType: GroupType,
    public ownerGroupId: string,
    public permissions: GroupPermissions,
    public forumId: string,
    public privacy: GroupPrivacy,
    public membersCount: number,
    public tags: GroupTags,
    public uploads: Upload[] = [],
    public wantedDaysOfExperience0 = 0,
    public wantedDaysOfExperience1 = 0,
    public wantedDaysOfExperience2 = 0,
    public wantedDaysOfExperience3 = 0,
    public wantedDaysOfExperience4 = 0,
    public wantedDaysOfExperience5 = 0,
    public videoUrl: string,
    public meetingLink: string,
    public signupLink: string,
    public eventDeadline: Date,
    public eventPeriod: TimePeriod = new TimePeriod()
  ) {}
}

export interface IEvent<T> extends IGroup<T> {
  attendingCount: number;
  declinedCount: number;
  noReplyCount: number;
  interestedCount: number;
  maybeCount: number;
  isCanceled: boolean;
  isOnline: boolean;
  onlineFormat: OnlineFormat;
  discountCode: string;
  eventDeadline: T;
  eventPeriod: JsonTimePeriod<T>;
  maxAttendees: number;
  signupLink: string;
  meetingLink: string;
}
