import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

@Component({
  selector: 'app-calendar-date-viewer',
  templateUrl: './calendar-date-viewer.component.html',
  styleUrls: ['./calendar-date-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarDateViewerComponent implements OnInit {
  @Input() date: Date;
  @Input() calendar = false;

  constructor() {}

  ngOnInit() {}
}
