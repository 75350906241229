import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
} from '@angular/core';

import { UserService } from 'app/core/user.service';
import { MondoUser } from 'app/shared/models/user/mondoUser';
import { Observable } from 'rxjs';
import { AuthService } from 'app/core/auth.service';
import { AccountType } from 'app/shared/consts/accountType';
import { tap } from 'rxjs/operators';
import { IndustryUser } from 'app/shared/models/user/industryUser';
import { ScientistUser } from 'app/shared/models/user/scientistUser';

@Component({
  selector: 'app-clickable-username',
  templateUrl: './clickable-username.component.html',
  styleUrls: ['./clickable-username.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClickableUsernameComponent implements OnInit {
  @Input() userId: string;
  @Input() showImg: boolean;
  @Output() userChanged = new EventEmitter<MondoUser>();
  user$: Observable<MondoUser>;
  constructor(
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.user$ = this.userService.getUserByUid$(this.userId).pipe(
      tap((user) => {
        this.userChanged.emit(user);
      })
    );
  }

  showPreview($event, user: MondoUser): void {
    $event.stopPropagation();
    $event.preventDefault();
    this.authService.showPreview(user);
  }

  getDisplayText(user: MondoUser, noProfiletext: string) {
    if (user.displayName) {
      return user.displayName;
    } else {
      return noProfiletext;
    }
  }
  getTitle(user: MondoUser) {
    if (AuthService.isUserOfCompanyType(user.type)) {
      // return (user as IndustryUser).company.;
      return '';
    } else if (AuthService.isUserOfScientistType(user.type)) {
      return (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.academicTitle
        ? `${(user as ScientistUser).personalDetails.academicTitle}`
        : '';
    } else {
      return '';
    }
  }
  getLocation(user: MondoUser) {
    if (AuthService.isUserOfCompanyType(user.type)) {
      // return (user as IndustryUser).company.;
      return '';
    } else if (AuthService.isUserOfScientistType(user.type)) {
      return (user as ScientistUser).personalDetails &&
        (user as ScientistUser).personalDetails.companyName
        ? `@${(user as ScientistUser).personalDetails.companyName}`
        : '';
    } else {
      return '';
    }
  }

  getTooltip(user: MondoUser, noProfiletext: string) {
    return `${this.getTitle(user)}${this.getLocation(user)}
    `;
  }

  isScientist(type: AccountType) {
    return AuthService.isUserOfScientistType(type);
  }
}
