import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  ComponentFactoryResolver,
  ViewContainerRef,
  ViewChild,
  ComponentRef,
} from '@angular/core';
import { Route, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserStatus, AuthService } from 'app/core/auth.service';
import {
  INavigationItem,
  MondoRoutes,
  RoutingModel,
} from 'app/app.routing-model';
import { AccountType } from 'app/shared/consts/accountType';
import { RoutingService } from 'app/core/routing.service';
import { MondoAccount } from 'app/shared/models/mondoAccount';
import { NavigationService } from 'app/core/navigation.service';
import { NotificationCenterComponent } from 'app/notifications-new/components/notification-center/notification-center.component';
import { SsoAuthService } from 'app/core/sso-auth.service';
import { hardcodedValues } from 'hardcodedValues';
import { AppInstallService } from 'app/core/app-install.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavbarComponent implements OnInit {
  navigationItems$: Observable<INavigationItem[]>;
  @Output() openSideNavEvent = new EventEmitter();
  routes = MondoRoutes;
  routingModel = RoutingModel;
  accountType: AccountType = AccountType.scientist;
  account: MondoAccount;
  private containerRef: ViewContainerRef;
  private notificationCenterComponentRef: ComponentRef<NotificationCenterComponent>;
  private get notificationCenter() {
    return this.containerRef;
  }
  @ViewChild('notificationCenter', { static: false, read: ViewContainerRef })
  private set notificationCenter(r) {
    this.containerRef = r;
    if (this.containerRef && !this.notificationCenterComponentRef) {
      this.loadComponent();
    }
  }

  loadComponent() {
    const factory =
      this.resolver.resolveComponentFactory<NotificationCenterComponent>(
        NotificationCenterComponent
      );
    this.notificationCenterComponentRef =
      this.notificationCenter.createComponent(factory);
  }

  userStatus$: Observable<UserStatus>;
  loggedIn = false;
  showBorderedButtons: boolean;
  showCapitalizedButtons: boolean;
  showNavSpacers: boolean;
  showAccountChangerInNavBar: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private routingService: RoutingService,
    private resolver: ComponentFactoryResolver,
    private navigationService: NavigationService,
    private ssoAuthService: SsoAuthService,
    private appInstallService: AppInstallService
  ) {}

  ngOnInit() {
    this.navigationItems$ = this.navigationService.navItems$;
    const accountTypeFromUrl = this.getAccountTypeFromUrl();
    this.accountType = accountTypeFromUrl;
    this.account = MondoAccount.fromAccountType(this.accountType);
    this.userStatus$ = this.authService.getUserStatus$();
    this.showBorderedButtons = hardcodedValues.showBorderedButtonsInNavBar;
    this.showCapitalizedButtons =
      hardcodedValues.showCapitalizedButtonsInNavBar;
    this.showNavSpacers = hardcodedValues.showNavSpacersInNavBar;
    this.showAccountChangerInNavBar =
      hardcodedValues.showAccountChangerInNavBar;
    // this.printpath('', this.router.config);
  }

  get showInstallPrompt() {
    return this.appInstallService.showInstallPrompt;
  }

  installPWA() {
    this.appInstallService.InstallPWA();
  }

  // printpath(parent: String, config: Route[]) {
  //   for (let i = 0; i < config.length; i++) {
  //     const route = config[i];
  //     console.log(parent + '/' + route.path);
  //     if (route.children) {
  //       const currentPath = route.path ? parent + '/' + route.path : parent;
  //       this.printpath(currentPath, route.children);
  //     }
  //   }
  // }

  private getAccountTypeFromUrl() {
    const urlParts = this.router.routerState.snapshot.url.split('/');
    const accountType = +urlParts[urlParts.length - 1];
    return accountType ? +accountType : AccountType.scientist;
  }

  signOut() {
    this.ssoAuthService.logout();
    this.authService.signOut();
  }

  public isAdmin() {
    return this.authService.isAdmin;
  }

  public onSideNavMenuClicked() {
    this.openSideNavEvent.emit();
  }

  isUserOfScientistType(type: AccountType) {
    return AuthService.isUserOfScientistType(type);
  }

  getChangeAccountTypeText(accountType: number) {
    return this.isUserOfScientistType(accountType) ? 'iAmACompany' : 'iAmAUser';
  }

  loggedInLogoClick() {
    this.routingService.goToAbout();
    // this.navigateToUserDefault();
  }

  navigateToUserDefault() {
    this.routingService.navigateToUserDefault(this.authService.getUserStatus());
  }

  navigateToSettings() {
    this.routingService.navigateToRoute(this.routingModel.profileEditor.path);
  }

  navigateToChat() {
    this.routingService.navigateToRoute(this.routingModel.chat.path);
  }

  navigateToAdminPage() {
    this.routingService.navigateToRoute(this.routingModel.adminPage.path);
  }

  changeAccountType(currentAccountType: number) {
    this.accountType = this.getOppositeAccountType(currentAccountType).type;
    this.account = MondoAccount.fromAccountType(this.accountType);
    this.routingService.navigateToUrlWithDataArray(
      RoutingModel.landingPage.path,
      [this.accountType]
    );
  }

  private getOppositeAccountType(accountType: number): MondoAccount {
    return this.isUserOfScientistType(accountType)
      ? MondoAccount.fromAccountType(AccountType.industry)
      : MondoAccount.fromAccountType(AccountType.scientist);
  }

  public getProfileFromAccountType(accountType: number) {
    this.routingService.navigateToUserProfile(accountType);
  }

  onAuxClick(e, route) {
    e.preventDefault();
    if (e.which === 2) {
      const url = this.router.serializeUrl(this.router.createUrlTree([route]));
      window.open(url, '_blank');
    }
  }

  navigateToExternal(e, route: string) {
    e.preventDefault();
    const link = document.createElement('a');
    link.href = route;
    link.target = '_blank';
    link.click();
  }

  navigateWithNoReuseTo(e, route: string) {
    e.preventDefault();
    this.routingService.navigateToUrlWithDataArrayWithNoReuse(
      route,
      Array.of({})
    );
  }

  showTerms() {
    if (hardcodedValues.termsText) {
      this.routingService.navigateToUrlWithDataArray(RoutingModel.gdpr.path, [
        'terms-and-conditions',
      ]);
    } else {
      window.open(hardcodedValues.termsLink, '_blank');
    }
  }

  showPrivacyPolicy() {
    if (hardcodedValues.termsText) {
      this.routingService.navigateToUrlWithDataArray(RoutingModel.gdpr.path, [
        'privacy-policy',
      ]);
    } else {
      window.open(hardcodedValues.privacyPolicyLink, '_blank');
    }
  }

  showWebAccessibility() {
    window.open(hardcodedValues.webAccessibilityLink, '_blank');
  }

  trackByFn(index, item) {
    return item.title;
  }
}
