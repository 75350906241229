import { Component, OnInit, Input } from '@angular/core';
import { acaConfig } from 'aca-config';
import { RoutingModel } from 'app/app.routing-model';
import { Community } from 'app/groups/communities/models/community';
import { CommunitiesService } from 'app/groups/communities/services/communities.service';
import { GroupRelation } from 'app/groups/models/group-relation';
import { SearchableTypes } from 'app/shared/common/search.model';
import { hardcodedValues } from 'hardcodedValues';
import { WidgetBaseComponent } from '../widget-base/widget-base.component';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-community-widget',
  templateUrl: './community-widget.component.html',
  styleUrls: ['./community-widget.component.scss'],
})
export class CommunityWidgetComponent
  extends WidgetBaseComponent
  implements OnInit
{
  @Input() groupKey = null;
  subgroups$: Observable<Community[]>;
  ownedCommunities: Community[] = [];
  administratorCommunities: Community[] = [];
  pendingInvites: Community[] = [];
  memberCommunities: Community[] = [];
  moderatorCommunities: Community[] = [];
  drafingCommunities: Community[] = [];
  searchString = '';
  searchResults: Community[] = [];

  SearchableTypes = SearchableTypes;
  RoutingModel = RoutingModel;

  constructor(private communitiesService: CommunitiesService) {
    super();
  }

  ngOnInit() {
    if (this.groupKey) {
      this.subgroups$ = this.getCommunitiesForGroup(this.groupKey);
    } else {
      this.safeSubscribe(
        this.communitiesService.getCommunities$(
          GroupRelation.Owner,
          !hardcodedValues.EnablePremiumCommunities
        ),
        (communities) => (this.ownedCommunities = communities)
      );
      this.safeSubscribe(
        this.communitiesService.getCommunities$(
          GroupRelation.Administrator,
          !hardcodedValues.EnablePremiumCommunities
        ),
        (communities) => (this.administratorCommunities = communities)
      );

      // this.safeSubscribe(
      //   this.communitiesService.getCommunities$(GroupRelation.Drafting),
      //   (drafingCommunities) => (this.drafingCommunities = drafingCommunities)
      // );

      this.safeSubscribe(
        this.communitiesService.getCommunities$(
          GroupRelation.Member,
          !hardcodedValues.EnablePremiumCommunities
        ),
        (memberCommunities) => (this.memberCommunities = memberCommunities)
      );

      this.safeSubscribe(
        this.communitiesService.getCommunities$(
          GroupRelation.Moderator,
          !hardcodedValues.EnablePremiumCommunities
        ),
        (communities) => (this.moderatorCommunities = communities)
      );

      this.safeSubscribe(
        this.communitiesService.getCommunities$(
          GroupRelation.Invited,
          !hardcodedValues.EnablePremiumCommunities
        ),
        (communities) => (this.pendingInvites = communities)
      );
    }
  }

  getCommunitiesForGroup(groupKey: string) {
    return this.communitiesService.getCommunitiesForGroup$(groupKey);
  }

  hasNotSearched() {
    return this.searchString === '';
  }

  searchChanged() {
    if (this.hasNotSearched()) {
      this.searchResults = [];
    } else {
      this.searchResults = [
        ...this.memberCommunities,
        ...this.moderatorCommunities,
        ...this.administratorCommunities,
        ...this.ownedCommunities,
      ].filter(
        (community) =>
          (community && community.name ? community.name : '')
            .toLowerCase()
            .indexOf(this.searchString.toLowerCase()) >= 0
      );
    }
  }

  showSearch() {
    return (
      (this.memberCommunities && this.memberCommunities.length > 4) ||
      (this.moderatorCommunities && this.moderatorCommunities.length > 4) ||
      (this.administratorCommunities &&
        this.administratorCommunities.length > 4) ||
      (this.ownedCommunities && this.ownedCommunities.length > 4)
    );
  }

  get TwitterListHref() {
    return acaConfig.TwitterListHref;
  }

  get ExternalFeedLeft() {
    return acaConfig.ExternalFeedLeft;
  }

  gotoCommunitiesRoute() {
    return this.groupKey ? '../sub' : RoutingModel.communities.route;
  }

  gotoCommunity(key: string) {
    return `${RoutingModel.communities.route}/${key}/feed`;
  }

  createNewDraftCommunity() {
    this.communitiesService.createCommunityAndNavigate();
  }

  canCreateNewDraftCommunity() {
    return this.communitiesService.canCreateNewDraftCommunity();
  }

  showCreateCommunityButtonForUserWithoutPermission() {
    return acaConfig.showCreateCommunityButtonForUserWithoutPermission;
  }

  trackByFn(index, item: Community) {
    return item && item.key;
  }
}
