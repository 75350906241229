import { MailFrequency } from '../src/app/notifications-new/models/mail-frequency';
import { acaConfig } from '../src/aca-config';
import { acaNames } from '../src/aca-names';

const common = {
  yourPost: 'dit indlæg',
  yourPosts: 'dine indlæg',
  aPost: 'et indlæg',
  thePost: 'indlægget',
  ThePost: 'Indlægget',
  newPost: 'nyt indlæg',
  NewPost: 'Nyt indlæg',
  NewPosts: 'Nye indlæg',
  post: 'indlæg',
  Post: 'Indlæg',
  posts: 'indlæg',
  Posts: 'Indlæg',
  PostBuilder: 'Indlægs bygger',
};

export const hardcodedValuesDK = {
  academondo: acaConfig.academondo,
  academondoDash: acaConfig.academondoDash,
  academondoCom: acaConfig.academondoCom,
  academondoUrl: acaConfig.academondoUrl,
  supportMail: acaConfig.supportMail,
  reviewMail: acaConfig.reviewMail,
  senderMail: acaConfig.senderMail,
  defaultUserCoverUrl: acaConfig.defaultUserCoverUrl,
  defaultEventCoverUrlCredit: acaConfig.defaultEventCoverUrlCredit,
  defaultEventCoverUrl: acaConfig.defaultEventCoverUrl,
  defaultCommunityCoverUrlCredit: acaConfig.defaultCommunityCoverUrlCredit,
  defaultCommunityCoverUrl: acaConfig.defaultCommunityCoverUrl,
  defaultIdeaCoverUrlCredit: acaConfig.defaultIdeaCoverUrlCredit,
  defaultIdeaCoverUrl: acaConfig.defaultIdeaCoverUrl,
  welcomeWidgetCoverUrlCredit: acaConfig.welcomeWidgetCoverUrlCredit,
  welcomeWidgetCoverUrl: acaConfig.welcomeWidgetCoverUrl,
  welcomeWidgetIcon: acaConfig['welcomeWidgetIcon']
    ? acaConfig['welcomeWidgetIcon']
    : false,
  welcomeWidgetIconInv: acaConfig['welcomeWidgetIconInv']
    ? acaConfig['welcomeWidgetIconInv']
    : false,
  welcomeWidgetLogo: acaConfig['welcomeWidgetLogo']
    ? acaConfig['welcomeWidgetLogo']
    : false,
  welcomeWidgetLogoInv: acaConfig['welcomeWidgetLogoInv']
    ? acaConfig['welcomeWidgetLogoInv']
    : false,
  EUWelcomeWidgetIcon: acaConfig['EUWelcomeWidgetIcon']
    ? acaConfig['EUWelcomeWidgetIcon']
    : false,
  EUWelcomeWidgetLogo: acaConfig['EUWelcomeWidgetLogo']
    ? acaConfig['EUWelcomeWidgetLogo']
    : false,
  welcomeWidgetVideo: acaConfig['welcomeWidgetVideo']
    ? acaConfig['welcomeWidgetVideo']
    : false,
  welcomeWidgetVideoHeadline: acaConfig['welcomeWidgetVideoHeadline']
    ? acaConfig['welcomeWidgetVideoHeadline']
    : false,
  welcomeWidgetVideoPrimaryColorBackground: acaConfig[
    'welcomeWidgetVideoPrimaryColorBackground'
  ]
    ? acaConfig['welcomeWidgetVideoPrimaryColorBackground']
    : false,
  showWelcomeWidget: acaConfig.showWelcomeWidget,

  // cookies
  AcceptFunctionalCookies: `Ok`,
  AcceptAllCookies: `Acceptér alle`,
  AcceptLearnMore: `Læs mere`,
  CookieOkay: `Okay`,
  get CookieInfoFunctional() {
    return acaConfig['CookieInfoFunctionalText']
      ? acaConfig['CookieInfoFunctionalText']
      : `Ved at fortsætte med at browse ${this.academondo} accepterer du brugen af cookies til at huske dit login efter du lukker browseren.`;
  },
  get CookieInfoMarketing() {
    return acaConfig['CookieInfoMarketingText']
      ? acaConfig['CookieInfoMarketingText']
      : `Ved at fortsætte med at browse ${this.academondo} accepterer du brugen af cookies til at huske dit login efter du lukker browseren.`;
  },
  get CookieDisclaimerFunctional() {
    return acaConfig['CookieDisclaimerFunctionalText']
      ? acaConfig['CookieDisclaimerFunctionalText']
      : `De cookies, der bruges til ${this.academondo} sporer ikke personlige oplysninger.`;
  },
  get CookieDisclaimerMarketing() {
    return acaConfig['CookieDisclaimerMarketingText']
      ? acaConfig['CookieDisclaimerMarketingText']
      : `${this.CookieDisclaimerFunctional}
    
    Hvis du accepterer alle cookies, kan vi vise dig et LinkedIn-feed. Dog indebærer dette brugen af sporingscookies.`;
  },

  // sign up
  byContinuingYouConsent:
    'Ved at forsætte giver du accept og er enig i brugen af dine data på følgende',
  termsLink: acaConfig.termsLink,
  termsText: acaConfig['termsText'] ? acaConfig['termsText'] : '',
  terms: 'betingelser',
  Terms: 'Betingelser',
  howWeTreatYourData:
    'Hvis du vil se mere om hvordan vi behandler dine data - se vores',
  privacyPolicyLink: acaConfig.privacyPolicyLink,
  privacyPolicyText: acaConfig['privacyPolicyText']
    ? acaConfig['privacyPolicyText']
    : '',
  get privacyPolicy() {
    return acaNames['privacyPolicy']
      ? acaNames['privacyPolicy']
      : 'privatlivspolitik';
  },
  get PrivacyPolicy() {
    return acaNames['PrivacyPolicy']
      ? acaNames['PrivacyPolicy']
      : 'Privatlivspolitik';
  },
  combineTermsAndPolicy: acaConfig['combineTermsAndPolicy']
    ? acaConfig['combineTermsAndPolicy']
    : false,
  combineTermsAndPolicyText: acaNames['combineTermsAndPolicyText']
    ? acaNames['combineTermsAndPolicyText']
    : '',
  copyRightDate: acaConfig.copyRightDate,
  showTermsInNavbarMenu: acaConfig.showTermsInNavbarMenu,

  webAccessibilityLink: acaConfig['webAccessibilityLink']
    ? acaConfig['webAccessibilityLink']
    : '',
  webAccessibility: 'tilgængelighedserklæring',
  WebAccessibility: 'Tilgængelighedserklæring',

  chatbotBuilderApiHost: acaConfig['chatbotBuilderApiHost']
    ? acaConfig['chatbotBuilderApiHost']
    : '',

  chatbotLangFuseApiHost: acaConfig['chatbotLangFuseApiHost']
    ? acaConfig['chatbotLangFuseApiHost']
    : '',
  chatbotLangSmithApiHost: acaConfig['chatbotLangSmithApiHost']
    ? acaConfig['chatbotLangSmithApiHost']
    : '',

  notEnoughPermission: acaNames.notEnoughPermission,
  YouDontHaveAccess: `Du har ikke adgang til den side`,
  welcomeToAcademondo: `Velkommen til ${acaConfig.academondo}`,
  welcomeToAcademondoDash: `Velkommen til ${acaConfig.academondo}`,
  welcomeToPublicUserProfile: 'Velkommen til din offentlige profil',
  suggestionSalute: `Tak for at gøre ${acaConfig.academondo} endnu bedre, dit forslag vil blive gennemgået`,
  sendSuggestion: 'Send forslag',

  academondoStreet: acaConfig.academondoStreet,
  academondoPostboxCity: acaConfig.academondoPostboxCity,
  academondoCVR: acaConfig.academondoCVR,
  academondoPhone: acaConfig.academondoPhone,

  twitterLink: acaConfig.twitterLink,
  facebookLink: acaConfig.facebookLink,
  linkedinLink: acaConfig.linkedinLink,
  instagramLink: acaConfig.instagramLink,
  fbPagePluginLink: acaConfig.fbPagePluginLink,

  // intro dialog/help
  welcomeToHeader: `Velkommen til ${acaConfig.academondo}`,
  // intro dialog - cv
  helpheaderCV: acaNames.helpheaderCV,
  helpLineOneCV: acaNames.helpLineOneCV,
  helpLineTwop1CV: acaNames.helpLineTwop1CV,
  helpLineTwop2CV: acaNames.helpLineTwop2CV,
  helpLineTwop3CV: acaNames.helpLineTwop3CV,
  helpLineThreep1CV: acaNames.helpLineThreep1CV,
  helpLineThreep2CV: acaNames.helpLineThreep2CV,

  // intro dialog - job
  helpheaderJob: acaNames.helpheaderJob,
  helpLineOneJob: acaNames.helpLineOneJob,
  helpLineTwoJob: acaNames.helpLineTwoJob,

  scienceMatchRoute: acaNames.scienceMatchRoute,
  scienceMatch: acaNames.scienceMatch,
  scienceMatchSubtitle: acaNames.scienceMatchSubtitle,
  scienceMatchSearchResults: 'Søgeresultater',
  get scienceMatchMatchYourTags() {
    return `Match dine ${this.tags}`;
  },
  showSearchSideBySide: `Side om side`,
  showSearchOneColumn: `En kolonne`,
  term: 'udtryk',
  SearchByX: `Søg efter {0}`,
  chooseX: `Vælg eller søg efter {0}...`,
  wantedX: `Ønskede {0}`,
  suggestNewX: `Foreslå "{0}"`,
  areYouSureWantToDeleteX: 'Er du sikker på du vil slette {0}?',
  facility: acaNames['facility'] ? acaNames['facility'] : 'arbejdsplads',
  Facility: acaNames['Facility'] ? acaNames['Facility'] : 'Arbejdsplads',
  name: 'navn',
  hideAdvancedSearchOptions: 'Skjul udvidet søgning',
  showAdvancedSearchOptions: 'Vis udvidet søgning',
  showInvitedAndMembers: `Vis inviterede og ${acaNames.members}`,
  showMaxSearchResultSelector: acaConfig.showMaxSearchResultSelector,
  maxDistanceInSearch: acaConfig.maxDistanceInSearch,
  maxSearchResults: acaConfig.maxSearchResults,
  showProfileWidgetStats: acaConfig.showProfileWidgetStats,
  showCreateButtonCommunityInProfileWidget:
    acaConfig.showCreateButtonCommunityInProfileWidget,
  showCreateButtonEventInProfileWidget:
    acaConfig.showCreateButtonEventInProfileWidget,
  showCreateButtonIdeaInProfileWidget:
    acaConfig.showCreateButtonIdeaInProfileWidget,

  ifYouHaveSuggestion: `Hvis du har et forslag som du ønsker at tilføje til vores database så hører vi gerne fra dig. Udfyld venligst formen herunder.`,
  description: 'beskrivelse',
  Description: 'Beskrivelse',

  TagDescRequired: acaConfig['TagDescRequired']
    ? acaConfig['TagDescRequired']
    : false,

  category: 'Kategori',
  Category: 'Kategori',
  categories: 'Kategorier',
  foi: `${acaNames.foi}`,
  Foi: `${acaNames.Foi}`,
  interests: `${acaNames.shortFoi}`,
  Interests: `${acaNames.ShortFoi}`,
  fois: `${acaNames.fois}`,
  Fois: `${acaNames.Fois}`,

  tech: acaNames.tech,
  Tech: acaNames.Tech,
  techs: acaNames.techs,
  Techs: acaNames.Techs,

  companySubtype: acaNames.companySubtype,
  CompanySubtype: acaNames.CompanySubtype,
  companySubtypes: acaNames.companySubtypes,
  CompanySubtypes: acaNames.CompanySubtypes,

  network: acaNames.network,
  Network: acaNames.Network,
  networks: acaNames.networks,
  Networks: acaNames.Networks,
  networkSubtype: acaNames['networkSubtype'] ? acaNames['networkSubtype'] : '',
  NetworkSubtype: acaNames['NetworkSubtype'] ? acaNames['NetworkSubtype'] : '',
  networkSubtypes: acaNames['networkSubtypes']
    ? acaNames['networkSubtypes']
    : '',
  NetworkSubtypes: acaNames['NetworkSubtypes']
    ? acaNames['NetworkSubtypes']
    : '',
  language: 'sprog',
  Language: 'Sprog',
  languages: 'sprogkundskaber',
  Languages: 'Sprogkundskaber',

  useLanguageForCV: acaNames.useLanguageForCV,
  category0: `${acaNames.category0}`,
  Category0: `${acaNames.Category0}`,
  categories0: `${acaNames.categories0}`,
  Categories0: `${acaNames.Categories0}`,
  category1: `${acaNames.category1}`,
  Category1: `${acaNames.Category1}`,
  categories1: `${acaNames.categories1}`,
  Categories1: `${acaNames.Categories1}`,
  category2: `${acaNames.category2}`,
  Category2: `${acaNames.Category2}`,
  categories2: `${acaNames.categories2}`,
  Categories2: `${acaNames.Categories2}`,
  category3: `${acaNames.category3}`,
  Category3: `${acaNames.Category3}`,
  categories3: `${acaNames.categories3}`,
  Categories3: `${acaNames.Categories3}`,
  category4: `${acaNames.category4}`,
  Category4: `${acaNames.Category4}`,
  categories4: `${acaNames.categories4}`,
  Categories4: `${acaNames.Categories4}`,
  category5: `${acaNames.category5}`,
  Category5: `${acaNames.Category5}`,
  categories5: `${acaNames.categories5}`,
  Categories5: `${acaNames.Categories5}`,
  Category0Hint: `${acaNames.Category0Hint}`,
  Category0Link: `${acaNames.Category0Link}`,
  Category0Dialog: acaNames.Category0Dialog,
  Category0Tooltip: acaNames.Category0Tooltip,
  Category1Hint: `${acaNames.Category1Hint}`,
  Category1Link: `${acaNames.Category1Link}`,
  Category1Dialog: acaNames.Category1Dialog,
  Category1Tooltip: acaNames.Category1Tooltip,
  Category2Hint: `${acaNames.Category2Hint}`,
  Category2Link: `${acaNames.Category2Link}`,
  Category2Dialog: acaNames.Category2Dialog,
  Category2Tooltip: acaNames.Category2Tooltip,
  Category3Hint: `${acaNames.Category3Hint}`,
  Category3Link: `${acaNames.Category3Link}`,
  Category3Dialog: acaNames.Category3Dialog,
  Category3Tooltip: acaNames.Category3Tooltip,
  Category4Hint: `${acaNames.Category4Hint}`,
  Category4Link: `${acaNames.Category4Link}`,
  Category4Dialog: acaNames.Category4Dialog,
  Category4Tooltip: acaNames.Category4Tooltip,
  Category5Hint: `${acaNames.Category5Hint}`,
  Category5Link: `${acaNames.Category5Link}`,
  Category5Dialog: acaNames.Category5Dialog,
  Category5Tooltip: acaNames.Category5Tooltip,
  FoiHint: `${acaNames.FoiHint}`,
  FoiLink: `${acaNames.FoiLink}`,
  FoiDialog: acaNames.FoiDialog,
  FoiTooltip: acaNames.FoiTooltip,
  TechHint: `${acaNames.TechHint}`,
  TechLink: `${acaNames.TechLink}`,
  TechDialog: acaNames.TechDialog,
  TechTooltip: acaNames.TechTooltip,
  showCategories3InCVEducationStep: acaNames.showCategories3InCVEducationStep,

  // email validation
  pressBelowToValidateEmail:
    'Tryk på knappen herunder for at bekræfte din email.',
  validateEmail: 'Bekræft email',
  validationFailedResendEmail:
    '❗ Bekræftigelse fejlede, gensend bekræftigelses email og tjek din indbakke/spam mappe.',
  resendValidationEmail: 'Gensend bekræftigelses email',
  enterNewPassword: 'Indtast nyt kodeord',
  confirmPassword: 'Bekræft kodeord',
  theGivenEmailIsNotVerified: 'Denne konto er ikke bekræftet.',
  thatEmailAlreadyExists:
    'Hvis denne email eksisterer, så er der sendt en mail.',
  wrongEmailOrPassword: 'Forkert email eller kodeord.',
  wrongEmail: 'Dårlig formatteret email',
  unknownError: 'Ukendt fejl!',
  'Unknown error!': 'Ukendt fejl!',
  forErrorsTryAgain: ' for fejl og prøv igen.',
  duplicateNewName: ' kopi',

  thankYouForUsing: `Tak for at du bruger ${acaConfig.academondo}! :)`,
  internetExplorerEdgeNotSupported: `Internet Explorer er usikker og ikke supporteret af ${acaConfig.academondo}.`,
  pleaseUseModernBrowser:
    'Brug venligtst en anden browser. Vi foreslår Chrome, Firefox, Safari eller Edge',
  getChrome: 'Hent Chrome',
  accountSelector1: acaNames.accountSelector1,
  accountSelector2: acaNames.accountSelector2,
  accountSelector3: acaNames.accountSelector3,
  accountSelector4: acaNames.accountSelector4,

  forceMembership: 'Tilføj',
  forceMembershipHint: `Du er ved at påtvinge medlemskab`,
  inviteToAcademondo: `Invitér til ${acaConfig.academondo}`,
  inviteToAcademondoText: acaNames.inviteToAcademondoText,
  Invite: 'Invitér',
  ok: 'ok',
  Ok: 'Ok',
  invite: 'Invitér',
  Uninvite: 'Tilbagetræk invitation',
  begin: 'Begynd',
  enter: 'Hop ind',
  cancel: 'Annullér',
  confirm: 'Bekræft',

  // Chat Bot
  ChatBot: 'Chat bot',
  get ChatBotText() {
    return `Titel`;
  },
  get groupChatBotTextLabel() {
    return `${this.ChatBot} titel`;
  },
  get groupChatBotTextTooltip() {
    return `Giv din ${this.ChatBot} et navn`;
  },
  get ChatBotKey() {
    return `Nøgle`;
  },
  get groupChatBotKeyLabel() {
    return `${this.ChatBot} nøgle`;
  },
  get groupChatBotKeyTooltip() {
    return `tilføj en nøgle for at tillade ${this.ChatBot}`;
  },

  // Kanban
  get Kanban() {
    return acaNames['Kanban'] ? acaNames['Kanban'] : 'Tasks';
  },
  KanbanDescription:
    'Optimer din projektstyring med vores kanban-app med drag-and-drop opgaver, beskrivelser, farvevalg og deadlines med nedtællingstimer.',
  ChooseATemplate: 'Vælg en skabelon',
  OrStartFromScratch: 'Eller start fra bunden',
  groupKanbanEnabled: acaConfig.groupKanbanEnabled,
  get showGroupKanbanPlugin() {
    return `Tillad ${this.Kanban}`;
  },
  Drag: 'Træk',
  task: 'opgave',
  Task: 'Opgave',
  board: 'board',
  Board: 'Board',
  get addKanban() {
    return `${this.add} ${this.Kanban} `;
  },
  get KanbanName() {
    return `${this.Kanban} titel`;
  },
  get KanbanText() {
    return `Hvad skal vi kalde denne ${this.Kanban}?`;
  },
  get addTask() {
    return `${this.add} ${this.task}`;
  },
  get updateTask() {
    return `${this.Update} ${this.task}`;
  },
  get TaskDescription() {
    return `${this.Task} ${this.description} `;
  },
  get addBoard() {
    return `${this.add} ${this.board} `;
  },
  get BoardTitle() {
    return `${this.Board} titel`;
  },
  get BoardText() {
    return `Hvad skal vi kalde dette ${this.board}?`;
  },

  acceptAccess: 'Acceptér',
  acceptRequestForMembership: 'Acceptér anmodning',
  rejectRequestForMembership: 'Afvis anmodning',
  request: 'anmod',
  requested: 'anmodet',
  Request: 'Anmod',
  Requested: 'Anmodet',
  invited: 'inviteret',
  Invited: 'Inviteret',
  selectedUsers: 'udvalgte brugere?',
  search: 'Søg',
  searchFor: 'Søg efter',

  showSearchForXButtonInProfileWidget:
    acaConfig.showSearchForXButtonInProfileWidget,
  showSearchForXButtonInProfileWidgetText:
    acaConfig.showSearchForXButtonInProfileWidgetText,

  clearSearch: 'Ryd søgning',
  withinARadiusOf: 'Indenfor en afstand af',
  radius: 'Km',
  distanceFromYou: 'Afstand',

  showAvailableJobs: `Aktuelle ${acaNames.jobs}`,
  showExpiredJobs: `Gamle ${acaNames.jobs}`,

  mostRecentExperience: 'Seneste erfaring',
  academicTitle: acaNames.academicTitle,
  subTitle: acaNames.subTitle,
  achievedAcademicDegree: 'Opnåede akademiske grad',
  any: 'Alle',
  NearYou: 'Nær dig',

  Experience0: acaNames.Experience0,
  experience0: `${acaNames.experience0}`,
  experience0Years: `${acaNames.Experience0} år`,
  ofExperience0Experience: `med ${acaNames.experience0} erfaring`,
  yearsOfExperience0: `år med ${acaNames.experience0} erfaring`,

  Experience1: acaNames.Experience1,
  experience1: `${acaNames.experience1}`,
  experience1Years: `${acaNames.Experience1} years`,
  ofExperience1Experience: `med ${acaNames.experience1} erfaring`,
  yearsOfExperience1: `år med ${acaNames.experience1} erfaring`,

  Experience2: acaNames.Experience2,
  experience2: `${acaNames.experience2}`,
  experience2Years: `${acaNames.Experience2} år`,
  ofExperience2Experience: `med ${acaNames.experience2} erfaring`,
  yearsOfExperience2: `år med ${acaNames.experience2} erfaring`,

  Experience3: acaNames.Experience3,
  experience3: `${acaNames.experience3}`,
  experience3Years: `${acaNames.Experience3} år`,
  ofExperience3Experience: `med ${acaNames.experience3} erfaring`,
  yearsOfExperience3: `år med ${acaNames.experience3} erfaring`,

  Experience4: acaNames.Experience4,
  experience4: `${acaNames.experience4}`,
  experience4Years: `${acaNames.Experience4} years`,
  ofExperience4Experience: `med ${acaNames.experience4} erfaring`,
  yearsOfExperience4: `år med ${acaNames.experience4} erfaring`,

  Experience5: acaNames.Experience5,
  experience5: `${acaNames.experience5}`,
  experience5Years: `${acaNames.Experience5} years`,
  ofExperience5Experience: `med ${acaNames.experience5} erfaring`,
  yearsOfExperience5: `år med ${acaNames.experience5} erfaring`,
  ExperienceUnit0: acaNames.ExperienceUnit0,
  ExperienceUnit1: acaNames.ExperienceUnit1,
  ExperienceUnit2: acaNames.ExperienceUnit2,
  ExperienceUnit3: acaNames.ExperienceUnit3,
  ExperienceUnit4: acaNames.ExperienceUnit4,
  ExperienceUnit5: acaNames.ExperienceUnit5,

  country: 'Land',
  degree: 'Grad',
  viewMore: 'Vis mere',
  viewLess: 'Vis mindre',
  viewProfile: 'Vis profil',
  searchForAcademondoCompanyProfiles: `Søg efter ${acaNames.sites}`,
  searchForAcademondoProfiles: `Søg efter ${acaNames.users}`,
  PublishedCVProfileTag: `${acaNames.Public} ${acaConfig.academondo} profil`,
  create: 'opret',
  Create: 'Opret',
  account: 'konto',
  Account: 'Konto',
  accountType: 'Kontotype',
  changeAccountType: 'Skift kontotype',
  createAccount: 'Opret konto',
  createNewCompany: `Opret ${acaNames.newsite}`,
  createNew: 'Opret ny',
  companyName: acaNames.companyName,
  CompanyName: acaNames.CompanyName,
  CompanyNameJob: acaNames.CompanyNameJob,
  login: 'Login',
  myProfile: 'Min Profil',
  MagicEmailSignIn: 'uden kodeord, kun med din email',
  GetLoginLink: 'Få linket',
  checkYourEmailForMagicLoginLink:
    'Tjek din email for at finde vores magiske link',
  'The email provided does not match the sign-in email address.':
    'Den indtastede email passer ikke med sign-in emailadressen',
  'The action code is invalid. This can happen if the code is malformed, expired, or has already been used.':
    'Linket er ugyldigt. Dette sker hvis dit link er udløbet eller allerede har været brugt.',
  enableMagicLink: acaConfig.enableMagicLink,

  iconIsUsedProfilePicture: 'Ikonet bruges som profilbillede i forumet',
  iconIsUsedOnMap: 'Ikonet bruges på kortet',
  pleaseEnterValidUrl: 'Venligst indtast en gyldig url',
  websiteUrl: acaNames.siteWebsiteUrl,
  networkWebsiteUrl: 'Hjemmeside url',

  writeInfoAboutCompany: acaNames.writeInfoAboutCompany,
  writeQualificationsPosition: acaNames.writeQualificationsPosition,
  iAmACompany: `Jeg er ${acaNames.asite}`,
  iAmAUser: `Jeg er ${acaNames.auser}`,
  UserDoesntExistAnymore: `Anonym`,

  selected: 'Valgte',
  JobType: `${acaNames.JobType}`,
  viewJob: `Vis ${acaNames.job}`,
  job: acaNames.job,

  JobType0: `${acaNames.JobType0}`,
  JobType1: `${acaNames.JobType1}`,
  JobType2: `${acaNames.JobType2}`,
  JobType3: `${acaNames.JobType3}`,
  JobType4: `${acaNames.JobType4}`,
  JobType5: `${acaNames.JobType5}`,
  JobType6: `${acaNames.JobType6}`,
  JobType7: `${acaNames.JobType7}`,
  JobType8: `${acaNames.JobType8}`,
  JobType9: `${acaNames.JobType9}`,

  jobType0Desc: `${acaNames.jobType0Desc}`,
  jobType1Desc: `${acaNames.jobType1Desc}`,
  jobType2Desc: `${acaNames.jobType2Desc}`,
  jobType3Desc: `${acaNames.jobType3Desc}`,
  jobType4Desc: `${acaNames.jobType4Desc}`,
  jobType5Desc: `${acaNames.jobType5Desc}`,
  jobType6Desc: `${acaNames.jobType6Desc}`,
  jobType7Desc: `${acaNames.jobType7Desc}`,
  jobType8Desc: `${acaNames.jobType8Desc}`,
  jobType9Desc: `${acaNames.jobType9Desc}`,

  newCompany: `${acaNames.Newsite}`,
  company: acaNames.site,
  Company: acaNames.Site,
  CompanyJobStep: acaNames.CompanyJobStep,
  companies: acaNames.sites,
  Companies: acaNames.Sites,
  user: acaNames.user,
  User: acaNames.User,
  users: acaNames.users,
  Users: acaNames.Users,

  admin: 'Admin',
  Specials: acaNames.Specials,
  UserType0: acaNames.UserType0,
  UserTypes0: acaNames.UserTypes0,
  UserType1: acaNames.UserType1,
  UserTypes1: acaNames.UserTypes1,
  UserType2: acaNames.UserType2,
  UserTypes2: acaNames.UserTypes2,
  UserType3: acaNames.UserType3,
  UserTypes3: acaNames.UserTypes3,
  UserType4: acaNames.UserType4,
  UserTypes4: acaNames.UserTypes4,
  UserType5: acaNames.UserType5,
  UserTypes5: acaNames.UserTypes5,

  AllUserTypesWithoutCV: `${acaNames.Users} uden ${acaNames.cv}`,
  AllUserTypesWithCV: `${acaNames.Users} med ${acaNames.cv}`,
  PermissionGrantedTo: 'Tilladelsesniveau givet til',
  AccountWasNotFoundWithThisEmail:
    'Der blev ikke fundet en konto med denne email',
  UsertypeChanged: 'Kontotype ændret',
  Grant: 'Giv',
  GrantOwnership: 'Overdrag ejerskab',
  GrantOwnershipAndRemoveOldOwner: 'Overdrag ejerskab og slet den gamle ejer',
  ThisCannotBeUndone: 'Dette kan ikke gøres om',
  permissionLevel: 'tilladelsesniveau',
  Permission: 'Tilladelse',
  get ChangePermissionLevel() {
    return `${this.Change} ${this.permissionLevel}`;
  },
  sidePanel: 'Side panel',
  visitOfficialWebsite: 'Hjemmeside',
  get visitCVWebsite() {
    return acaNames['visitCVWebsite']
      ? acaNames['visitCVWebsite']
      : this.visitOfficialWebsite;
  },
  clickToCopy: 'klik for at kopiere email',
  profileBuilder: `${acaNames.Site}`,

  InstallPWA: `Få app'en`,
  iosInstallTitle: `Installation Instruks for iOS`,
  iosInstallInfo: `Tak for at bruge vores app! Desværre har iOS ikke support for automatisk installation for web apps. Tilgengæld, kan du nemt tilføje vores app til din hjemmeskærm ved at følge disse steps:`,
  iosInstallLI1: 'Åbn Safari og navigér til vores hjemmeside.',
  Tap: 'Tryk',
  iosInstallLI2: '',
  iosInstallLI3: 'Scroll ned til du ser "Føj til hjemmeskærm"og tryk på den.',
  iosInstallLI4: 'Indtast et navn og tryk "tilføj".',
  iosInstallLI5:
    'App ikonet er nu på din hjemmeskærm! Du kan åbne den præcis som med andre apps. Dettte giver dig også muligheden for at få push beskeder, som du finder under notifikationsindstillingerne',

  both: 'Begge',

  unableToFindAnythingMatchingYourSearchCriteria:
    'Vi kan ikke finde noget der passer på dine søgekriterier..',

  pleaseChooseSomeSearchCriteria: 'Vælg venligst nogle søgekriterier.',

  modeSwitcher: 'Skift visning',

  awaitingApproval: 'Anmodet',
  youHaveBeenInvited: 'Du er allerede inviteret',

  contractLength: 'Kontrakt længde',
  permanentContract: acaNames.permanentContract,
  pasteAccessNumber: 'Indsæt adgangsnummer og tryk søg',
  year: 'år',
  Year: 'År',
  s: '', // set to '' if no ending on plural of year
  yearss: 'år',

  years: 'år',
  months: 'måned(er)',
  monthss: 'måneder',
  month: 'måned',
  MonthsMayOnlyBeBetween0And11: 'Måneder må kun være mellem 0 og 11.',
  minimumWorkExperience: 'Minimums arbejdserfaring',
  minimumEducationalLevel: 'Minimums uddannelsesniveau',
  educationalLevel: 'Uddannelsesniveau',
  selectMinimumEducationalLevel: 'Vælg minimums uddannelsesniveau',

  // date picker
  startDate: 'Start dato',
  endDate: 'Slut dato',
  startTime: 'Starttidspunkt',
  endTime: 'Sluttidspunkt',

  // personal details
  firstName: 'Fornavn',
  middleName: 'Mellemnavn',
  showMiddleName: acaConfig.showMiddleName,
  allowMultipleItems: acaConfig.allowMultipleItems,
  surname: 'Efternavn',
  Sex: 'Køn',
  showSexInPersonalDetails: acaConfig.showSexInPersonalDetails,
  showDepartmentInPersonalDetails: acaConfig.showDepartmentInPersonalDetails,
  dateOfBirth: 'Fødselsdato',
  dateOfBirthHint: acaNames.dateOfBirthHint,

  address: 'Adresse',
  siteAddressDisclaimer: `Ved at tilføje en adresse vises ${acaNames.thesite} på kortet`,
  placeAddressDisclaimer: `Ved at tilføje en adresse vises ${acaNames.theplace} på kortet`,
  cvAddressDisclaimer: acaNames['cvAddressDisclaimer']
    ? acaNames['cvAddressDisclaimer']
    : `Tilføj din adresse(arbejde eller hjemme), så kommer du på kortet`,
  cvAddress: 'Adresse',

  addressSelectorInvalid: 'Indtast en gyldig adresse...',

  uploadProfilePicture: 'Upload profilbillede',
  writeAboutYourself: acaNames.writeAboutYourself,
  showGithub: acaNames.showGithub,
  showOrcId: acaNames.showOrcId,
  showTwitter: acaNames.showTwitter,
  personalGithub: 'Github',
  personalOrcId: 'ORCID',
  personalTwitter: 'Twitter',
  orcId: 'ORCID',
  github: 'Github',
  twitter: 'Twitter',
  enterAValidGithub:
    'Indtast en gyldig github url eks. https://www.github.com/user',
  githubUrl: 'https://www.github.com/user',
  orcIdUrl: 'https://www.orcid.org/...',
  enterAValidTwitter:
    'Indtast en gyldig twitter url eks. https://www.twitter.com/user',
  twitterUrl: 'https://www.twitter.com/user',
  personalLinkedin: 'Personlig LinkedIn',
  companyLinkedin: acaNames['companyLinkedin']
    ? acaNames['companyLinkedin']
    : 'LinkedIn',
  linkedin: 'LinkedIn',
  get linkedinRemark() {
    return acaNames['linkedinRemark']
      ? acaNames['linkedinRemark']
      : `Når du tilmelder dig ved hjælp af ${this.linkedin}, bemærk venligst, at den primære e-mail, du har tilknyttet din ${this.linkedin}-konto, vil være den, du modtager notifikationer fra ${this.academondo} på.`;
  },
  enterAValidLinkedin:
    'Indtast en gyldig linkedin url eks. https://www.linkedin.com/in/navn',
  enterAValidUrl: 'Indtast en gyldig url eks. https://academondo.com',
  linkedUrl: 'https://www.linkedin.com/in/navn',

  // education
  titleOfEducation: 'Titel',
  titleOfEducationHint: acaNames.titleOfEducationHint,
  lineOfEducation: 'Uddannelses linje',
  educationalInstitution: 'Institution',
  department: 'afdeling',
  Department: 'Afdeling',
  writeProjectsQualificationsDuringStudies:
    'Skriv om projekter og kvalifikationer du har opnået gennem studiet',
  currentEducation: 'Nuværende uddannelse',
  newEducation: 'Ny uddannelse',

  // conference
  title: 'Titel',
  NoTitle: 'Ingen titel',
  city: 'By',
  contribution: 'Bidrag',
  insertAbstract: 'Indsæt abstract',
  writeConference: 'Skriv om konferencen og dit bidrag',
  newWorkshopConference: 'Ny Workshop/konference',

  // researchStay
  newResearchStayStudiesAbroad: 'Nyt studie i udlandet',
  universityInstitution: 'Universitet/institution',
  lab: 'Lab', // laboratory
  writeAboutResearchStayQualifications:
    'Skriv om de kvalifikationer du har fået under dit studie udlandsophold',
  currentResearchStay: 'Nuværende studie i udlandet',

  // teaching exp
  titleOfTeachingExperience: 'Undervisningserfarings titel',
  writeAboutSpecificTopics: 'Skriv om de emner du har givet lektioner i',
  currentlyTeaching: 'Nuværende undervisning',
  newTeachingExperience: 'Ny undervisningserfaring',

  // work exp
  writeAboutPositionQualificationsRequiredPosition:
    'Skriv om stillingen og kvalifikationerne der kræves i denne stilling',
  employmentType: 'Ansættelsesforhold',
  companyInstitution: acaNames.Site,
  jobTitle: 'titel',
  JobTitle: 'Titel',
  currentEmployment: 'Nuværende ansættelse',
  newWorkExperience: 'Ny Arbejdserfaring',

  // additional information
  additionalInformationEtc: acaNames.additionalInformationEtc,
  writeTheRelevantInformation: acaNames.writeTheRelevantInformation,
  excludeFromCV: 'Vis ikke i profil',
  updateCV: 'Opdatér profil',

  // publications
  searchThroughPubmed: 'Søg på pubmed',
  searchByNameTitleOfPublication: 'Søg efter navn eller titel på publikationen',
  searchResults: 'Søgeresultater',
  clearSearchResults: 'Ryd søgeresultater',
  addAll: 'Tilføj alle',
  addedPublications: 'Tilføjede publikationer',
  removeAllArticles: 'Fjern alle artikler',
  someArticlesWithoutTitleRemoved: 'Nogle artikler uden titel blev fjernet',
  someArticlesWereAlreadyAddedPublications: `Nogle artikler var allerede tilføjet`,

  // education types
  None: 'Ingen',
  'Academy Profession': 'Akademi uddannelse',
  Bachelor: 'Bachelor',
  Master: 'Kandidat',
  'Ph.D': 'Ph.D',
  Other: 'Anden',

  // cv-builder
  saveProfileHelper:
    'Når du er klar til at gemme, skal du venligst sikre dig, at alle ovennævnte krav er opfyldt. Når alt er på plads, vil du se en procentdel på 100% og en gem-knap vil blive synlig.',
  basisStepTitle: 'Basisprofil',
  basisStepNextBtnText: 'Fortsæt til udvidet',
  showTitleadditionalInformation: acaNames.showTitleadditionalInformation,
  additionalInformation: acaNames.additionalInformation,
  viewTemplate: 'Vis skabelon',
  beginBuildingYourCV: `Byg ${acaNames.yourcv}`,
  personalDetails: 'Personlige detajler',
  showTitleintroductoryText: acaNames.showTitleintroductoryText,
  introductoryText: acaNames.introductoryText,
  workExperience: 'Arbejdserfaring',
  education: 'Uddannelse',
  researchStayStudiesAbroad: 'Studie i udlandet',
  conferenceAndWorkshops: 'Konferencer og workshops',
  teachingExperience: 'Undervisningserfaring',
  publications: 'Publikationer',
  get saveToAcademondo() {
    return `Gem på ${this.academondo}`;
  },
  print: 'Print',
  profileCVBuilder: `${acaNames.CV} bygger`,
  press: 'Tryk',
  makeCVVisibleEmployersLatestChanges:
    'for at gøre din profil synlig eller opdater det med dine seneste ændringer',
  seeCoauthors: 'Tjek for at en liste af medforfattere',
  youHaveToAddX: '{0} krævet',
  duplicateCVDone: `${acaNames.CV} duplikeret!`,

  // cv-viewer
  showSiteTagsAsChips: acaConfig.showSiteTagsAsChips,
  showCvTagsAsChips: acaConfig.showCvTagsAsChips,
  current: 'Nuværende',
  educationAndWorkExperience: 'Uddannelse og arbejdserfaring',
  conferencesAndWorkshops: 'Konferencer og workshops',

  // job-builder
  PastJobs: `Udløbne ${acaNames.jobs}`,
  jobWantedExperience: acaConfig.jobWantedExperience,
  jobStartTitle: 'Start',
  image: 'billede',
  Image: 'Billede',
  images: 'billeder',
  Images: 'Billeder',
  jobStartStep1: acaNames.jobStartStep1,
  jobStartStep2: acaNames.jobStartStep2,
  beginBuildingYourJobPost: acaNames.beginBuildingYourJobPost,
  jobInformation: 'Information',
  researchQualifications: 'Kategorisering',
  employmentPeriod: acaNames.employmentPeriod,
  contactInformation: 'Kontakt information',
  visibilityPeriod: 'Synlighedsperiode',
  PublishJob: acaNames.PublishJob,
  PublishJobs: acaNames.PublishJobs,
  uploadLogo: 'Upload logo til profil',
  URLToTheOfficialJobSite: acaNames['URLToTheOfficialJobSite']
    ? acaNames['URLToTheOfficialJobSite']
    : `URL til den officielle side`,
  URLToTheOfficialJobApplicationForm: acaNames[
    'URLToTheOfficialJobApplicationForm'
  ]
    ? acaNames['URLToTheOfficialJobApplicationForm']
    : `URL til den officielle formular`,
  pasteURLHere: 'Indsæt URL her',
  reseachGroup: 'Afdeling',
  researchLeader: 'Kontaktperson',
  writeNamesAndInformationOnThePeopleToContact:
    'Skriv navne og information om kontaktpersoner.',
  permanentPosition: 'Permanent',
  temporaryPosition: 'Midlertidig',
  startAsSoonAsPossible: 'Hurtigst muligt',
  titleOfThePosition: 'Overskrift',
  writeInformationAboutThePositionTasksResponsibilities: `Beskriv ${acaNames.thejob}`,
  PressTheButtonBelowOpenPublishMenu: `Tryk på knappen herunder for at åbne  menuen. Her kan du se status, ${acaNames.toPublish}, opdatere eller eller fjerne ${acaNames.yourjob}.`,
  get YourApplicationDeadline6WeeksFromPublicationDate() {
    return `${this.applicationDeadline} kan være op til 3 måneder fra ${this.publicationDate}. Når ${acaNames.thejob} udløber bliver det taget ned 1 uge senere`;
  },
  makeVisibleToday: `Gør synligt idag`,
  applicationDeadline: 'Udløbsdato',
  publicationDate: 'Startdato',
  cvr: 'CVR',
  invalid: 'ugyldigt',
  valid: 'gyldigt',

  cvrLengthError: 'CVR skal have en længde på 8',
  cvrRequiredError: 'CVR skal udfyldes',
  numberOfMaxApplicants: 'Maksimalt antal ansøgere',
  Apply: 'Ansøg',
  ApplyCommunity: acaNames.ApplyCommunity,
  ApplyCommunityTooltip: acaNames['ApplyCommunityTooltip']
    ? acaNames['ApplyCommunityTooltip']
    : acaNames['ApplyCommunity'],
  ApplyEvent: acaNames.ApplyEvent,
  ApplyEventTooltip: acaNames['ApplyEventTooltip']
    ? acaNames['ApplyEventTooltip']
    : acaNames['ApplyEvent'],
  ApplyIdea: acaNames.ApplyIdea,
  ApplyIdeaTooltip: acaNames['ApplyIdeaTooltip']
    ? acaNames['ApplyIdeaTooltip']
    : acaNames['ApplyIdea'],
  ApplyFilter: 'Anvend filter',
  ResetFilter: 'Nulstil filter',
  userAppliedToJob: 'Åbn Bryder model',
  ShowInterestApplySimple: 'Vis interesse',
  ShowInterestApliedSimple: 'Interesse vist',
  SimpleApplicationText: 'Vis interesse!',
  SimpleApplicationTextSuccess: 'Tak!',
  noMoreApplicantsTooltip: ' tager ikke imod flere ansøgninger',
  numberOfApplicants: 'Antal ansøgere',
  applicationEmail: 'Email',
  applicantionEmailRequiredError: 'Email skal udfyldes',

  // job publish menu
  publishMenu: acaNames.publishMenu,
  status: 'Status',
  numberOfPublishedJobs: `Antal ${acaNames.publishedPlural} ${acaNames.jobs}`,
  youCanPublishToMakeThisJobPublic: `Du kan ${acaNames.toPublish} for at gøre dette søgbart.`,
  draftHasBeenEdited: `Kladden er blevet ændret. Du kan ${acaNames.toPublish} dine ændringer.`,
  unpublishToRemove: 'Tilbagetræk for at fjerne den offentlige version.',
  publicFrom: acaNames.Published,
  yourCurrentPublicPeriod: 'Din nuværende periode er sat til',

  NoChangesHasBeenMade: 'Du har ikke lavet nogen ændringer',

  yourOldPublicPeriod: 'Din tidligere periode var sat til',

  youNeedToProvideVisibilityPeriod: 'Du skal udfylde en synligsperiode.',
  youHaveReachedMaximumPublishedJobs: `Du har nået dit maksimale antal af offentlige ${acaNames.jobs}, opgradér din plan eller tilbagetræk nogle ${acaNames.jobs}. Ræk ud til: ${acaConfig.supportMail}, for mere info`,
  unpublish: 'Tilbagetræk',
  unpublished: `ikke ${acaNames.published} endnu`,
  queuedForPublishing: `Sat i kø og ${acaNames.publishing}`,
  publishing: `${acaNames.Publishing}...`,
  published: acaNames.Published,
  republish: 'Genindryk',

  oldPost: `${acaNames.Oldjob}`,
  Rejected: 'Afvist',

  new: 'nyt',
  New: 'Nyt',
  updateCompanyDetails: 'Opdatér oplysninger',
  YouHavePublishedJobWithNewDetails: `Du har ${acaNames.published} ${acaNames.ajob} med nye ${acaNames.site}soplysninger. vil du opdatere din profil med ændringerne?`,
  yourCVIsPublished: `${acaNames.Yourcv} er nu ${acaNames.published}!`,
  yourJobIsQueued: `${acaNames.Yourjob} er sat i kø!`,
  yourCompanyIsPublished: `${acaNames.Site} er nu ${acaNames.published}!`,
  YourPostIsPublished: `${acaNames.Yourjob} er nu ${acaNames.published}!`,
  YourPlaceIsPublished: `${acaNames.theplace} er nu ${acaNames.published}!`,
  // job-viewer
  about: 'Om',
  aboutTheCompany: 'Om virksomheden',
  aboutThePosition: 'Beskrivelse',
  officialJobApplication: 'Officielle formular',
  officialJobSite: 'Officielle side',
  jobApplication: 'Formular',
  relevantLinks: 'Links',
  jobSite: 'Side',
  starting: 'Start dato',
  asSoonAsPossible: 'Hurtigst muligt',
  ending: 'Slut dato',
  experience: 'erfaring',
  qualifications: 'Kvalifikationer',
  deadlineToday: `Udløber i dag`,
  deadlineExceeded: `Udløbet`,
  eventDeadlineToday: `Tilmeldingsfrist i dag`,
  eventDeadlineExceeded: `Tilmeldingsfrist overskredet`,
  eventEndsToday: `Slutter i dag`,
  eventHasEnded: `Har været afholdt`,
  NewExternalLink: 'Nyt link',
  newExternalLink: 'nyt link',
  externalLinks: 'Eksterne links',
  externalLinksDesc: 'Tilføj links som menupunkter',
  externalLinkText: 'Link titel',
  externalLinkTextPlaceholder: 'Titel eks. Colab',
  externalLinkUrl: 'Indsæt link',
  externalLinkUrlPlaceholder: 'Link eks. colab.google',

  // site-builder
  showSiteInfoStep: acaConfig.showSiteInfoStep,
  showCVRInputInSiteInfo: acaConfig.showCVRInputInSiteInfo,
  siteInfoStepTitle: acaNames.siteInfoStepTitle,
  siteInfoDate: acaNames.siteInfoDate,
  siteInfoDateHint: acaNames.siteInfoDateHint,
  siteQuestion0: acaNames.siteQuestion0,
  siteQuestion0Hint: acaNames.siteQuestion0Hint,
  siteQuestion0Link: acaNames.siteQuestion0Link,
  siteQuestion0Dialog: acaNames.siteQuestion0Dialog,
  siteQuestion0Tooltip: acaNames.siteQuestion0Tooltip,
  siteQuestion1: acaNames.siteQuestion1,
  siteQuestion1Hint: acaNames.siteQuestion1Hint,
  siteQuestion1Link: acaNames.siteQuestion1Link,
  siteQuestion1Dialog: acaNames.siteQuestion1Dialog,
  siteQuestion1Tooltip: acaNames.siteQuestion1Tooltip,
  siteQuestion2: acaNames.siteQuestion2,
  siteQuestion2Hint: acaNames.siteQuestion2Hint,
  siteQuestion2Link: acaNames.siteQuestion2Link,
  siteQuestion2Dialog: acaNames.siteQuestion2Dialog,
  siteQuestion2Tooltip: acaNames.siteQuestion2Tooltip,
  siteQuestion3: acaNames.siteQuestion3,
  siteQuestion3Hint: acaNames.siteQuestion3Hint,
  siteQuestion3Link: acaNames.siteQuestion3Link,
  siteQuestion3Dialog: acaNames.siteQuestion3Dialog,
  siteQuestion3Tooltip: acaNames.siteQuestion3Tooltip,
  siteQuestion4: acaNames.siteQuestion4,
  siteQuestion4Hint: acaNames.siteQuestion4Hint,
  siteQuestion4Link: acaNames.siteQuestion4Link,
  siteQuestion4Dialog: acaNames.siteQuestion4Dialog,
  siteQuestion4Tooltip: acaNames.siteQuestion4Tooltip,
  priceOnSite: acaConfig.priceOnSite,
  slimSiteIndex: acaConfig.slimSiteIndex,
  siteIsUserDriven: acaConfig.siteIsUserDriven,
  siteStartStep1: `Velkommen til ${acaNames.site}byggeren, her opretter du ${acaNames.thesite}.`,
  siteStartStep2: `Husk at ${acaNames.toPublish} ${acaNames.site}en ved at trykke på gem til sidst`,
  beginBuildingYourSite: `Byg ${acaNames.site}`,
  email: 'email',
  emails: 'emails',
  Email: 'Email',
  Emails: 'Emails',
  SiteEmail: `${acaNames.Site} email`,
  saveYourProfile: `Gem ${acaNames.thesite}`,
  saveProfile: `Gem ${acaNames.thesite}`,
  addImage: 'Tilføj billede',
  addFiles: 'Tilføj filer',
  addVideo: 'Tilføj video',
  uploadCover: 'Upload cover',
  uploadIcon: 'Upload logo til oversigt',
  uploadImageLink: 'Upload billede',
  CatchPhrase: 'Slogan',
  siteIdeaStatus: 'Status',
  siteIdeaStatusHint: ``,
  siteIdeaStatusLink: '',
  siteIdeaStatusDialog: false,
  siteIdeaStatusTooltip: false,
  SiteIdeaActive: 'Aktiv',
  SiteIdeaDormant: 'Sovende',
  SiteIdeaClosed: 'Lukket',

  // multi-selector
  weFoundNothingThatMatchesYourSearch:
    'Vi kan ikke finde noget der passer på dine søgekriterier.',
  useTheSearchFieldAboveToBeginSelection:
    'brug søgefeltet ovenfor for at starte.',

  // login
  resendVerificationMail: 'Send bekræftelsesmail',
  verificationMailSent: `❗ Bekræftelsesmail afsendt,  husk at tjekke din indbakke/spam mappe.`,
  verificationMailSentToUser: 'Bekræftelsesmail afsendt.',
  pleaseVerifyYourEmail: 'Bekræft venligst din email',
  dontHaveAnAccount: 'Har du ikke en konto?',
  signUp: acaNames.signUp,
  disabledSignupEmail: acaConfig['disabledSignupEmail']
    ? acaConfig['disabledSignupEmail']
    : false,
  disabledSignupForm: acaConfig['disabledSignupForm']
    ? acaConfig['disabledSignupForm']
    : false,
  get DontHaveAccountText() {
    return acaNames['DontHaveAccountText']
      ? acaNames['DontHaveAccountText']
      : this.dontHaveAnAccount;
  },
  get DontHaveAccountLink() {
    return acaNames['DontHaveAccountLink']
      ? acaNames['DontHaveAccountLink']
      : '';
  },
  get SignupButtonText() {
    return acaNames['SignupButtonText']
      ? acaNames['SignupButtonText']
      : this.signUp;
  },
  emailAddressAlreadyInUse: `Du har forsøgt at skrive dig op, men du har allerede en konto. Prøv at logge på eller nulstille dit kodeord, hvis du har glemt dit kodeord.`,
  SignupAs: 'Opret dig som',
  signIn: 'Log på',
  containLeastOneNumberAndOneCapitalLetter:
    'Skal indeholde en blanding af store og små bogstaver samt mindst et tal',
  mustBetween6And24Characters: 'Skal være mellem 6 og 24 karakterer',

  EnterYourEmailAddressAndPasswordToAccessAccount:
    'Enter your email address and password to access account',

  // forum
  beginBuildingYourPost: `Byg ${common.yourPost}`,
  generalInformation: 'Info',
  topics: 'Tags',

  postBuilder: `${common.PostBuilder}`,
  titleOfAnnouncement: `titel`,
  TitleOfAnnouncement: `Titel`,
  writeInformationAboutYourRequest: `Skriv information om ${common.yourPost}.`,
  useLinkButton: 'Brug link knappen (',
  toAddLinks: ') nedenunder for at tilføje links.',

  // comments
  yourMessage: 'Din besked...',
  yourComment: 'Din kommentar...',
  youNeedToTypeText: 'du skal skrive en tekst',
  commentHint: 'du skal skrive en tekst eller tilføje et billede',
  Save: 'Gem',
  Close: 'Luk',
  reply: 'Svar',
  showReplies: 'Vis svar',
  hideReplies: 'Skjul svar',
  showMore: 'Vis mere',
  showLess: 'Vis mindre',
  get showTags() {
    return `Vis ${this.tags}`;
  },
  get hideTags() {
    return `Skjul ${this.tags}`;
  },
  hideAll: 'Skjul alle',
  comments: 'kommentarer',
  loadMore: 'Indlæs flere...',
  InviteMore: 'Invitér flere...',
  loadMoreComments: 'Indlæs flere kommentarer',
  Loading: 'Indlæser',
  lastSeen: 'sidst set',
  LastSeen: 'Sidst set',
  edited: 'ændret',
  Edited: 'Ændret',
  charsLeft: 'karakterer tilbage',
  unfoldComments: 'Fold ud',
  foldComments: 'Fold ind',

  defaultStartStep1:
    'Velkommen til profilbyggeren, her opretter du din profil.',
  defaultStartStep2: `Husk at ${acaNames.toPublish} profilen ved at trykke på gem til sidst`,

  // partners
  weProudlyPresentPartners: 'We proudly present our partners',

  // profileviewer
  Printable: 'Printbar',
  noPublicProfile: `${acaNames.Yourcv} er ikke ${acaNames.public} endnu, opret og gem for at begynde!`,
  makeYourProfile: 'Lav din profil',
  noProfileWithThatID: `${acaNames.CV} med det id, findes ikke.`,
  pleaseSignInToSeeProfiles: `Log på for at se ${acaNames.cvs}`,
  seeYourOwnProfile: `Se ${acaNames.yourcv}`,
  saveChanges: 'Gem ændringer',
  ChangesSaved: 'Gemt',

  followUs: 'Følg os',
  phone: 'Tlf',
  contact: 'Kontakt',

  contactEmail: 'Email',
  contactPhone: 'Tlf',
  resetYourPassword: 'nulstille dit kodeord',
  ResetYourPassword: 'Nulstil dit kodeord',
  resetPassword: 'Nulstil kodeord',
  forgotPassword: 'Glemt kodeord',
  recoverAccount: 'Genskab konto',
  pressBelowToRecoverAccount:
    'Tryk på knappen herunder for at genskabe din konto',
  accountEmailRestoredYouMightWantToResetYourPassword:
    'Konto email er blevet genskabt. Du bør nok nulstille dit kodeord',

  EnterYourEmailToGetStarted: 'Indtast din email for at starte',

  resetPWEmailSent:
    '❗Hvis du har en konto, så vil et nulstil kodeord link blive sendt til din email, tjek din inbox/spam mappe',

  // new Email
  changeEmail: 'Skift email',
  newEmailConfirm: 'Er du sikker på at du vil ændre din konto email?',
  NewEmail: 'Ny email',
  newEmailText: `Emailen er nu skiftet.. Næste gang du logger på skal du bruge samme kodeord samt bekræfte den nye email`,

  password: 'Kodeord',
  optional: 'Valgfri',
  accept: 'acceptére',
  Accept: 'Acceptér',
  decline: 'afvis',
  Decline: 'Afvis',
  accepting: 'Accepterer',
  declining: 'Afviser',
  anInvite: 'denne invitation',

  // emptyProfile
  createYourProfileAndLetTheWorldDiscoverYou:
    'Opret din profil og lad verden finde dig',
  Profile: 'Profil',
  profile: 'profil',
  yourFree: 'din', // gratis
  You: 'Du',
  Yourself: 'Dig',
  Your: 'Dit',
  Name: 'Navn',
  yourTitle: 'Din titel',
  yourAddress: 'Din adresse',
  aboutYourCompanyInstittution: 'Om din virksomhed',
  yourMostRecentOccupation: 'Din seneste beskæftigelse',

  // filter-tags
  get removeAllTags() {
    return `Fjern alle ${this.tags}`;
  },

  // profile-editor
  defaultNotificationFreq: acaConfig['defaultNotificationFreq']
    ? acaConfig['defaultNotificationFreq']
    : MailFrequency.Asap,
  defaultNotificationFreqForCompanies: acaConfig[
    'defaultNotificationFreqForCompanies'
  ]
    ? acaConfig['defaultNotificationFreqForCompanies']
    : MailFrequency.Asap,
  profileData: 'Profil information',
  notificationSettings: 'Notifikationer',
  SaveNotificationSettings: 'Gem notifikationsindstillinger',
  PushNotifications: 'Push notifikationer',
  PushNotification: 'Push notifikation',
  AlertPushTitle: 'Overskrift',
  AlertPushMessage: 'Besked',
  TheNotificatioPermissionNotGranted: `Du har valgt at blokere notifikationer. For at ændre dit valg, kan du trykke på hængelåsen ved siden af url'en i din browser og tillade notifikationer igen.`,

  // notification settings
  SetNotificationSettingsToNever: 'Slå alle notifikationer fra',
  SetNotificationSettingsToAsap: 'Sæt notifikationsfrekvensen til asap',
  SetNotificationSettingsToDaily: 'Sæt notifikationsfrekvensen til dagligt',
  SetNotificationSettingsToWeekly: 'Sæt notifikationsfrekvensen til ugentligt',
  SetNotificationSettingsToMonthly: 'Sæt notifikationsfrekvensen til månedligt',
  Notifications: 'Notifikationer',
  Never: 'Aldrig',
  Asap: 'Asap',
  Reminder: 'Påmindelse',
  Daily: 'Daglig',
  Weekly: 'Ugentlig',
  Monthly: 'Månedlig',

  NewReplyToYourComment: `Ny kommentar til en kommentar du har lavet`,
  get taggedInAComment() {
    return `Du er ${this.tagged} i en kommentar`;
  },
  get taggedInAPost() {
    return `Du er ${this.tagged} i en ${this.post}`;
  },

  // Notifications
  userAcceptedGroupInviteFromYou_title: `{0} accepterede din invitation`,
  userAcceptedGroupInviteFromYou_message: `{0} deltager i {1}`,
  userRejectedCommunityInviteFromYou_title: `{0} afviste din invitation`,
  userRejectedCommunityInviteFromYou_message: `{0} afviste din invitation til {1}`,
  ownerInvitedUserToCommunity_title: `Invitation`,
  ownerInvitedUserToCommunity_message: `{0} inviterede dig til at deltage i {1}`,
  userRequestsCommunityMembership_title: `Medlemsanmodning til {0}`,
  userRequestsCommunityMembership_message: `{0} har anmodet medlemskab til {1}`,
  ownerAcceptsCommunityMembershipRequest_title: `Din anmodning til {0} blev accepteret`,
  ownerAcceptsCommunityMembershipRequest_message: `{0} har accepteret din anmodning til {1}`,
  ownerRejectsCommunityMembershipRequest_title: `Din anmodning til {0} blev afvist`,
  ownerRejectsCommunityMembershipRequest_message: `{0} har afvist din anmodning til {1}`,
  youAreMadeOwnerOfGroup_title: `Du er nu ejer af {0}`,
  youAreMadeOwnerOfGroup_message: `{0} har givet dig ejerskabet af {1}`,
  youAreMadeAdministatorOfGroup_title: `Du er nu administrator af {0}`,
  youAreMadeAdministatorOfGroup_message: `{0} har forfremmet dig i {1}`,
  memberJoinedYourCommunity_title: `Nyt ${acaNames.member} i {0}`,
  memberJoinedYourCommunity_message: `{0} deltager i {1}`,
  youWereKickedFromCommunity_title: `Du blev fjernet fra {0}`,
  youWereKickedFromCommunity_message: `{0} fjernede dig fra {1}`,
  ownerInvitedUserToEvent_title: `Invitation til ${acaNames.event}`,
  ownerInvitedUserToEvent_message: `{0} inviterede dig til {1}`,
  memberAttendingYourEvent_title: `{0} deltager`,
  memberAttendingYourEvent_message: `{0} deltager i {1}`,
  memberMaybeAttendingYourEvent_title: `{0} deltager måske`,
  memberMaybeAttendingYourEvent_message: `{0} har markeret sig som måske deltagende {1}`,
  memberDeclinedYourEvent_title: `{0} deltager ikke`,
  memberDeclinedYourEvent_message: `{0} deltager ikke {1}`,
  memberIsInterestedYourEvent_title: `{0} er interesseret`,
  memberIsInterestedYourEvent_message: `{0} er interesseret i {1}`,
  newCommentInPostYouCreated_title: `{0} tilføjet en ny kommentar til ${common.yourPost}`,
  newReactionOnPostYouCreated_title: `Du har fået et "{0}" af {1} på ${common.yourPost} "{2}"`,
  newReactionOnPostYouCreated_message: `Du har nu "{0}" reaktioner på "{1}"`,
  newReactionOnCommentYouCreated_title: `Du har fået et "{0}" af {1} på din kommentar "{2}"`,
  newReactionOnCommentYouCreated_message: `Du har nu "{0}" reaktioner på "{1}"`,
  newReplyToYourComment_title: `{0} svarede på din kommentar`,
  newCommentToPostYouCommented_title: `{0} tilføjede en ny kommentar`,
  newPostInGroupWereYouAreMember_title: `{0} tilføjede et ${common.newPost} til {1}`,
  newPostInGroupWereYouOwn_title: `{0} tilføjede et ${common.newPost} til {1}`,
  newChatMessage_title: `{0} sendte dig en besked`,
  get newPostMatchingYourTags_title() {
    return `{0} tilføjede et ${common.newPost}, der matcher dine ${this.tags}, til {1}`;
  },
  get newJobMatchingYourTags_title() {
    return `{0} tilføjede ${acaNames.ajob}, der matcher dine ${this.tags}.`;
  },
  newGroupMatchingYourTags_subject: `{0} har oprettet {1} på {2}, der passer din profil`,

  get newGroupMatchingYourTags_title() {
    return `{0} matcher dine ${this.tags}: {1}`;
  },

  get taggedYouInAComment_title() {
    return `{0} ${this.tagged} dig i en kommentar`;
  },

  get taggedYouInAPost_title() {
    return `{0} ${this.tagged} dig i en ${this.post}`;
  },

  // Notification Settings
  EnablePushNotificationsDescription: `Når du aktiverer push notifikationer på din mobil, vil du ikke modtage push beskeder på computeren, og omvendt. Det betyder, at du kun modtager push beskeder på det device, hvor du har aktiveret notifikationerne.`,
  comments_notificationSetting: `Kommentarer`,
  community_notificationSetting: `${acaNames.Communities}`,
  communityOwners_notificationSetting: `Egne ${acaNames.communities}`,
  posts_notificationSetting: `${common.Posts}`,
  newPostInGroupYouOwn_notificationSetting: `${common.NewPosts} i ${acaNames.communities} eller ${acaNames.events} du ejer`,
  newPostInGroupYouAreMember_notificationSetting: `${common.NewPosts} i grupper(${acaNames.communities} etc.), hvor du er medlem`,
  get newPostMatchingYourTags_notificationSetting() {
    return `${common.NewPosts}, der matcher dine ${this.tags}`;
  },
  newGroupMatchingYourTags_notificationSetting: `(${acaNames.community}${
    acaNames.Event ? '/' + acaNames.event : ''
  }${acaNames.Idea ? '/' + acaNames.idea : ''}), der matcher din profil`,
  requestedMembership_notificationSetting: `Anmodninger`,
  rejectedInvite_notificationSetting: `Afviste invitationer`,
  memberLeft_notificationSetting: `Bruger forlader`,
  memberJoined_notificationSetting: `Bruger deltagelse`,
  acceptedInvite_notificationSetting: `Accepterede invitationer`,
  get newCommentinPostWithTagMatch_notificationSetting() {
    return `Ny kommentar i  ${common.post} med matchende ${this.tags}`;
  },
  kicked_notificationSetting: `Smidt ud`,
  invited_notificationSetting: `Invitationer`,
  acceptedRequest_notificationSetting: `Anmodning accepteret`,
  rejectedRequest_notificationSetting: `Anmodning afvist`,
  newCommentInPostYouFollow_notificationSetting: `Nye kommentarer i  ${common.post} du følger`,
  newCommentInPostYouCreated_notificationSetting: `Nye kommentarer i  ${common.post} du har oprettet`,
  newCommentInPostYouCommented_notificationSetting: `Nye kommentarer i  ${common.post} du har kommenteret`,
  newReplyToYourComment_notificationSetting: `Svar til dine kommentarer`,
  get taggedInAComment_notificationSetting() {
    return `${this.Tagged} i en kommentarer`;
  },
  get taggedInAPost_notificationSetting() {
    return `${this.Tagged} i ${this.posts}`;
  },
  promoted_notificationSetting: `Forfremmelser`,
  eventOwners_notificationSetting: `Egne ${acaNames.events}`,
  event_notificationSetting: `${acaNames.Events}`,
  interested_notificationSetting: `Interesserede brugere`,
  declined_notificationSetting: `Afvisninger`,
  maybe_notificationSetting: `Måske brugere`,
  attending_notificationSetting: `Deltagende brugere`,
  requestedAttendance_notificationSetting: `Anmodninger`,

  expiration_notificationSetting: 'Udløb',
  cvExpiration_notificationSetting: `${acaNames.CV}`,
  siteExpiration_notificationSetting: `${acaNames.Site}`,
  jobExpiration_notificationSetting: `${acaNames.Job}`,

  chat_notificationSetting: 'Chat',
  chatMessage_notificationSetting: 'Beskeder',

  reaction_notificationSetting: 'Reaktioner',
  job_notificationSetting: acaNames.Jobs,
  get newJobMatchingYourTags_notificationSetting() {
    return `${acaNames.Jobs}, der matcher dine ${this.tags}`;
  },

  noNotificationText: 'Du har ikke nogen notifikationer',
  markAsRead: 'Markér alle som læst',
  settings: 'indstillinger',
  Notificationsettings: 'Notifikationsindstillinger',

  invitation: 'invitation',
  inApp: 'in app',
  optOuts: 'opt outs',
  addedA: 'tilføjet et',
  in: 'i',

  // uploading
  anyoneWithLinkCanDownloadFile: 'NB: alle med dette link kan hente filen',
  uploads: 'Uploads',
  uploadsDescription: `Denne sektion viser uploadede filer fra alle ${common.posts}.
  Du kan vedhæfte filer når du opretter ${common.posts}
  `,
  dragAndDropArea: 'Drag and Drop',
  orChooseFile: 'eller vælg en fil…',
  downloadMe: 'Hent!',
  of: ' af ',
  pause: 'Pause',
  resume: 'Genoptag',

  yourCVs: `Dine ${acaNames.cvs}`,
  yourJobs: `Dine ${acaNames.jobs}`,
  sideBySide: 'Side om side',
  builderOnly: 'Kun bygger',
  viewerOnly: 'Kun visning',
  sorting: 'Sortering',
  help: 'Hjælp',
  selectAnotherImage: 'Vælg et andet billede',
  yes: 'Ja',
  no: 'Nej',
  becomeMemberCommunity: `${acaNames.becomeMemberCommunity}`,
  becomeMemberEvent: `${acaNames.becomeMemberEvent}`,
  becomeMemberIdea: `${acaNames.becomeMemberIdea}`,
  becomeMemberCommunityTooltip: acaNames['becomeMemberCommunityTooltip']
    ? acaNames['becomeMemberCommunityTooltip']
    : acaNames.becomeMemberCommunity,
  becomeMemberEventTooltip: acaNames['becomeMemberEventTooltip']
    ? acaNames['becomeMemberEventTooltip']
    : acaNames.becomeMemberEvent,
  becomeMemberIdeaTooltip: acaNames['becomeMemberIdeaTooltip']
    ? acaNames['becomeMemberIdeaTooltip']
    : acaNames.becomeMemberIdea,
  enterValidMail: 'Indtast en gyldig e-mail adresse.',
  mustBe2Characters: 'skal være mindst 2 karakterer.',
  mustBeFilledOut: 'skal udfyldes.',
  required: 'påkrævet',
  Required: 'Påkrævet',
  delete: 'Slet',
  deletePost: `Slet ${common.post}`,
  deleteCV: `Slet ${acaNames.cv}`,
  deleteJob: `Slet ${acaNames.job}`,
  deleteCompany: `Slet ${acaNames.site}`,
  newCV: acaNames.CV,
  post: common.post,
  Post: common.Post,
  posts: common.posts,
  Posts: common.Posts,
  thePost: common.thePost,
  newPost: common.newPost,
  NumberOfPosts: `Antal ${common.posts}`,
  NumberOfPostsSubTitle: `Viser kun datoer med mere end 1 ${common.post}`,
  NumberOfPostsPast14DaysSubTitle: `Seneste 14 dage`,
  NumberOfPostsPast1YearSubTitle: `Seneste år`,
  NumberOfPostsPast2YearSubTitle: `Forrige år`,
  newJobPosition: acaNames.newJobPosition,
  uploadPdfLabel: `Upload filer til ${common.yourPost}`,
  uploadPdf: 'Upload pdf',
  uploadPdfTooltip: 'Upload pdf',
  openProfileTooltip: `Åbn ${acaNames.cv} på en ny tab`,
  showDateOnPostsInFeedWidget: acaConfig.showDateOnPostsInFeedWidget,
  showMemberCountOnEventWidget: acaConfig.showMemberCountOnEventWidget,

  // network
  GroupStatistics: 'Grupper',
  filter: `Filtrer`,
  createNewPost: `Opret ${common.newPost}`,
  createNewEvent: `Opret ${acaNames.newEvent}`,
  createNewSubgroup: `Opret ${acaNames.newSubgroup}`,
  remove: 'Fjern',
  areYouSureYouWantToX: 'Er du sikker på du vil {0}?',
  areYouWantToLeaveX: 'Er du sikker på du vil forlade {0}?',

  edit: 'Rediger',
  Edit: 'Rediger',
  rename: 'Omdøb',
  renameCV: `Rename ${acaNames.thecv}`,
  renameJob: `Rename ${acaNames.thejob}`,
  duplicate: 'Duplikér',
  done: 'Færdig',
  add: 'Tilføj',
  back: 'tilbage',
  Back: 'Tilbage',
  next: 'næste',
  Next: 'Næste',
  logOut: 'Log ud',
  Collapse: 'Skjul',
  maximize: 'Maksimer',
  minimize: 'Minimer',
  hide: 'Skjul',
  deviceLocationTooltip: 'Tillad lokation for at søge nær dig',
  noJobExists: `Der findes ikke et ${acaNames.job} med dette ID`,
  jobsMatchingYourQualifications: `Log på for at søge efter ${acaNames.jobs} der passer til dine kvalifikationer`,
  landingPageRoute: 'velkommen',

  Beginner: 'Begynder',
  Conversational: 'Konversation',
  Proficient: 'Dygtig',
  Fluent: 'Flydende',
  Bilingual: 'Tosproget',
  Native: 'Modersmål',

  // Account Permission
  Basic: acaNames.Basic,
  Extended: acaNames.Extended,
  Full: acaNames.Full,
  Admin: acaNames.Admin,

  GoToForum: 'Gå til opslagstavle',
  Forum: 'Opslagstavle',
  calendar: 'Kalender',
  member: `${acaNames.member}`,
  Member: `${acaNames.Member}`,
  members: `${acaNames.members}`,
  Members: `${acaNames.Members}`,
  participant: `${acaNames.participant}`,
  Participant: `${acaNames.Participant}`,
  participants: `${acaNames.participants}`,
  Participants: `${acaNames.Participants}`,
  participantInvite: `${acaNames.participantInvite}`,
  ParticipantInvite: `${acaNames.ParticipantInvite}`,
  participantsInvite: `${acaNames.participantsInvite}`,
  ParticipantsInvite: `${acaNames.ParticipantsInvite}`,
  administrative: 'Administrativ',
  employee: 'Medarbejder ',
  employees: 'medarbejdere ',
  employeesDash: 'Medarbejdere - ',

  // network types
  subEventDisclaimer: `${acaNames.Thisevent} inviterer automatisk alle ${acaNames.members} fra hoved-`,
  Public: acaNames.Public,
  OpenPrivate: acaNames.OpenPrivate,
  Private: acaNames.Private,
  Hidden: acaNames.Hidden,
  InviteOnly: acaNames.InviteOnly,
  public: acaNames.public,
  openPrivate: acaNames.openPrivate,
  private: acaNames.private,
  inviteOnly: acaNames.inviteOnly,
  hidden: acaNames.hidden,

  University: 'Universitet',
  Organisation: 'Organisation',

  // invite chips
  all: 'alt',
  All: 'Alt',
  Alls: 'Alt',
  universities: 'universiteter',
  organisations: 'organisationer',
  hospitals: 'hospitaler',

  // routes
  CVBuilderRoute: acaNames.CVRoute,
  CVBuilderTitle: acaNames.CV,
  CVSearchRoute: 'Profil Søgning',
  jobBuilderRoute: acaNames.jobBuilderRoute,
  jobBuilderTitle: acaNames.jobBuilderTitle,
  adminRoute: 'Admin',
  profileEditorRoute: 'Profil indstillinger',
  profileRoute: 'Profil',
  jobRoute: `${acaNames.Job}`,
  mapRoute: 'kort',
  mapTitle: 'Kort',
  filesRoute: 'Filer',
  aboutUsRoute: 'Sådan gør du',
  aboutJobSearchRoute: `Find ${acaNames.ajob}`,
  aboutJobBuilderRoute: `Post ${acaNames.ajob}`,
  newUserRoute: 'Ny bruger',
  chatRoute: 'Chat',
  cookiesRoute: 'Cookies',
  partnersRoute: 'Partnere',
  dashboardRoute: acaNames.dashboardRoute,
  dashboardTitle: acaNames.dashboardTitle,
  homeRoute: 'firma',
  searchRoute: 'Søgning',
  messagesRoute: 'Beskeder',
  siteBuilderRoute: acaNames.siteBuilderRoute,
  jobListRoute: `${acaNames.Job}skatalog`,
  siteListRoute: `${acaNames.Site}skatalog`,
  cvListRoute: `${acaNames.User} katalog`,
  postSearchRoute: `Posts`,
  companySearchRoute: `${acaNames.Sites}`,
  candidateSearchRoute: `${acaNames.Users}`,
  homeProfileRoute: 'Profil',

  groupChat: 'Chat',
  Chats: 'Chats',
  OpenChat: 'Chat',
  enableChat: acaConfig.enableChat,
  groupChatEnabled: acaConfig.groupChatEnabled,
  DisableChat: 'Deaktivér chatfunktionen',
  DisableChatDescription: `Ved at deaktivere chatfunktionen, vil det være umuligt for andre brugere at starte en samtale med dig, men hvis du selv sender en besked, vil de være i stand til at svare. Du kan stadig starte en samtale med andre profiler.`,

  // router hints
  thesite: acaNames.thesite,
  site: acaNames.site,
  Site: acaNames.Site,
  sites: acaNames.sites,
  Sites: acaNames.Sites,
  CV: acaNames.CV,
  cv: acaNames.cv,
  CVs: acaNames.CVs,
  cvs: acaNames.cvs,

  // publishDialog
  thisWillMakeCVSearchable: acaNames.thisWillMakeCVSearchable,
  thisWillUpdateYourCurrentOnline: 'Dette vil opdatere din nuværende',
  Continue: 'Fortsæt',
  giveTheCVAName: `Giv ${acaNames.thecv} et navn`,
  giveTheJobAName: `Giv ${acaNames.thejob} et navn`,

  // about-us
  Testimonials: 'Statements',
  Partners: 'Partnere',
  Visit: 'Besøg',
  Founders: 'Stiftere',

  // application process
  notRegistered: 'ikke registeret',
  checkPdfAtPage: 'se bryder modellen på side 56',
  applicationMaterialLinkUrl:
    'https://www.akademikerkampagnen.dk/wp-content/uploads/2019/01/SKABv%C3%A6rdi_Ebog_komprimeret.pdf',
  applicationMaterialLinkText: 'Bryder model',
  applicationProcessLine1: 'Din opgave er nu at følge denne model',
  applicationProcessLine2: 'og sende din ansøgning',
  applicationProcessLine3: 'Held og lykke, benyt nedenstående email',
  applicationProcessLine4:
    'Ps. du kan altid vende tilbage til denne dialog ved at søge jobbet frem igen',
  maxApplicationsReceived: 'Fyldt',
  OnlyNumbersAllowedDontUseDash: 'kun tal er tilladt, bring ikke bindestreg',
  CprMustBeFilledOut: 'CPR nummer skal udfyldes.',
  EnterCprWithoutDash: 'Indtast CPR uden bindestreg',
  tryAgain: 'Prøv igen',
  pleaseProvideCPRToCompleteYourRegistration:
    'Ved at fortsætte accepterer du at benytter vi dit CPR nummer til din ansøgning',
  sendYourApplicationToThisEmail: 'Send din ansøgning til denne mail',
  ApplicationSuccess: 'Tak for din ansøgning, fortsæt til bryder modellen',
  Applications: 'Ansøgninger',
  cpr: 'CPR',
  CPRHint: acaNames.CPRHint,
  CPRLink: acaNames.CPRLink,
  CPRDialog: acaNames.CPRDialog,
  CPRTooltip: acaNames.CPRTooltip,
  somethingWentWrongAttemptingToApplyForJob:
    'Noget gik galt, prøv venligst igen',
  cvTechs: `${acaNames.CV} ${acaNames.techs}`,
  jobTechs: `${acaNames.Job} ${acaNames.techs}`,
  jobCat0: `${acaNames.Job} ${acaNames.category0}`,
  GraduateProgram: acaNames.GraduateProgram,
  GraduateProgramHint: acaNames.GraduateProgramHint,
  GraduateProgramLink: acaNames.GraduateProgramLink,
  GraduateProgramDialog: acaNames.GraduateProgramDialog,
  GraduateProgramTooltip: acaNames.GraduateProgramTooltip,
  GraduateProgramRequired: `${acaNames.GraduateProgram}?`,
  AreYouOnDimittendsats: acaNames.AreYouOnDimittendsats,
  Dimittendsats: acaNames.Dimittendsats,
  DimittendsatsHint: acaNames.DimittendsatsHint,
  DimittendsatsLink: acaNames.DimittendsatsLink,
  DimittendsatsDialog: acaNames.DimittendsatsDialog,
  DimittendsatsTooltip: acaNames.DimittendsatsTooltip,
  DimittendsatsRequired: `${acaNames.Dimittendsats}?`,
  AreYouOnAkademikerkampagnen: acaNames.AreYouOnAkademikerkampagnen,
  Akademikerkampagnen: acaNames.Akademikerkampagnen,
  AkademikerkampagnenHint: acaNames.AkademikerkampagnenHint,
  AkademikerkampagnenLink: acaNames.AkademikerkampagnenLink,
  AkademikerkampagnenDialog: acaNames.AkademikerkampagnenDialog,
  AkademikerkampagnenTooltip: acaNames.AkademikerkampagnenTooltip,
  AkademikerkampagnenRequired: `${acaNames.Akademikerkampagnen}?`,

  SSOEnabled: acaConfig.SSOEnabled,
  LinkedinEnabled: acaConfig.LinkedinEnabled,

  enabledSubgroups: acaConfig.enabledSubgroups,
  enabledSubgroupsInIdeas: acaConfig.enabledSubgroupsInIdeas,
  moveUpcomingGroupsToBottom: acaConfig['moveUpcomingGroupsToBottom']
    ? true
    : false,
  expireProfiles: acaConfig.expireProfiles,
  expireProfilesWarning: acaConfig['expireProfilesWarning']
    ? acaConfig['expireProfilesWarning']
    : 30,
  expireProfilesDeadline: acaConfig['expireProfilesDeadline']
    ? acaConfig['expireProfilesDeadline']
    : 60,
  get expireProfilesHint() {
    return `${acaNames.CVs} udløber ${this.expireProfilesDeadline} dage efter sidste opdatering`;
  },
  expireProfilesNotificationTitle: `${acaNames.CV} udløbsnotifikation`,
  get expireProfilesNotificationMessage() {
    return `${acaNames.Yourcv} er ved at udløbe.
  30 dage uden en opdatering og ${acaNames.yourcv} bliver fjernet fra søgningen, men ${acaNames.yourcv} slettes ikke`;
  },
  expiredProfilesNotificationMessage: `${acaNames.Yourcv} er udløbet.
  login og gem for at gøre det synligt igen`,
  onlyCreateIdeasFromSite: acaConfig.onlyCreateIdeasFromSite,
  onlyCreateIdeasFromSiteDisclaimer: `Husk at opdatere tilknyttede ${acaNames.ideas}, hvis du laver ændringer her`,
  expireSites: acaConfig.expireSites,
  expireSitesHint: `${acaNames.Sites} udløber 365 dage efter sidste opdatering`,
  expireSitesNotificationTitle: `${acaNames.Site} udløbsnotifikation`,
  expireSitesNotificationMessage: `${acaNames.Site} er ved at udløbe.
  7 dage uden en opdatering og ${acaNames.thesite} bliver fjernet fra søgningen, men ${acaNames.thesite} slettes ikke`,
  jobImages: acaConfig.jobImages,
  enableJobs: acaConfig.enableJobs,
  jobResearchQualificationsAsFilters:
    acaConfig.jobResearchQualificationsAsFilters,
  expireJobsNotificationTitle: `${acaNames.Job} udløbsnotifikation`,
  expireJobsNotificationMessage: `${acaNames.Yourjob} udløber i morgen.
  lav en ny udløbsdato eller ${acaNames.thejob} bliver fjernet fra søgningen, men ${acaNames.thejob} slettes ikke`,

  registerCVRONSites: acaConfig['registerCVRONSites']
    ? acaConfig['registerCVRONSites']
    : false,

  useCategory2AsCategory0: acaConfig['useCategory2AsCategory0']
    ? acaConfig['useCategory2AsCategory0']
    : false,

  enabledGroupPushNotifications: acaConfig['enabledGroupPushNotifications']
    ? acaConfig['enabledGroupPushNotifications']
    : false,

  showCommunityWidgetFirst: acaConfig['showCommunityWidgetFirst']
    ? acaConfig['showCommunityWidgetFirst']
    : false,

  enableCoupling: acaConfig.enableCoupling,
  showApplicationProcessFunctions: acaConfig.showApplicationProcessFunctions,
  registerVisitFunctions: acaConfig.registerVisitFunctions,
  showRelatedJobsFunctions: acaConfig.showRelatedJobsFunctions,
  showCalendarInNetworks: acaConfig.showCalendarInNetworks,
  showBorderedButtonsInNavBar: acaConfig.showBorderedButtonsInNavBar,
  showCapitalizedButtonsInNavBar: acaConfig.showCapitalizedButtonsInNavBar,
  showNavSpacersInNavBar: acaConfig.showNavSpacersInNavBar,
  showAccountChangerInNavBar: acaConfig.showAccountChangerInNavBar,
  showAccountSelectorIcons: acaConfig.showAccountSelectorIcons,
  showChangeAccountTypeInSignup: acaConfig.showChangeAccountTypeInSignup,
  showSiteImageOnMap: acaConfig.showSiteImageOnMap,
  showPlaceImageOnMap: acaConfig.showPlaceImageOnMap,
  showNetworkImageOnMap: acaConfig.showNetworkImageOnMap,
  showCommunityImageOnMap: acaConfig.showCommunityImageOnMap,
  ideaWantedExperience: acaConfig.ideaWantedExperience,
  ideaImages: acaConfig.ideaImages,
  showIdeaImageOnMap: acaConfig.showIdeaImageOnMap,
  showEventImageOnMap: acaConfig.showEventImageOnMap,
  showCreatorOfCommunities: acaConfig.showCreatorOfCommunities,
  showCreatorOfEvents: acaConfig.showCreatorOfEvents,
  showCreatorOfIdeas: acaConfig.showCreatorOfIdeas,
  showOptionToRemoveUsersPostsAndEvEventsOnLeaving:
    acaConfig.showOptionToRemoveUsersPostsAndEvEventsOnLeaving,
  showOptionToRemoveUsersPostsAndEvEventsOnRemovingUser:
    acaConfig.showOptionToRemoveUsersPostsAndEvEventsOnRemovingUser,
  showInviteWidget: acaConfig.showInviteWidget,
  showBasicStep: acaConfig.showBasicStep,
  RegisterDirectMatch: acaConfig.RegisterDirectMatch,
  contactConsentGiven: acaConfig.contactConsentGiven,
  showCreateCommunityButtonForUserWithoutPermission:
    acaConfig.showCreateCommunityButtonForUserWithoutPermission,
  showCreateEventButtonForUserWithoutPermission:
    acaConfig.showCreateEventButtonForUserWithoutPermission,
  showCreateIdeaButtonForUserWithoutPermission:
    acaConfig.showCreateIdeaButtonForUserWithoutPermission,
  showPrintSiteButton: acaConfig.showPrintSiteButton,

  // suggestion

  PleaseReviewSuggestionFromX:
    'Gennemgå venligst dette forslag fra ({0}) så hurtigt som muligt...',
  yourSuggestion: 'Dit forslag',
  hasBeenAddedThanks: 'er blevet tilføjet, tak for hjælpen.',
  areYouSureYouWantToDeleteTheSuggested: 'Er du sikker på du vil slette',
  areYouSureYouWantToAddX: 'Er du sikker på du vil tilføje {0}?',
  suggestTagDescription: `Vælg fra listen eller foreslå dit eget.
  Bemærk: Forslag skal godkendes af en administrator. Du skal huske at tilføje dit forslag til ${acaNames.yourcv} efter det er blevet til godkendt.`,
  enableCategory0Suggestions: acaConfig.enableCategory0Suggestions,

  // fenerum

  PleaseCheckThatThisIsYourCorrectBillingInformation:
    'Tjek venligst at betalingsinformation er korrekte.',
  ThankYouYourAccountHasBeenCreated: 'Tak! Din betalingskonto er nu oprettet.',
  SetupAccountingEmail: 'Betalingskonto registrering',
  TryAgain: 'Prøv igen',
  line: 'linje',
  Zipcode: 'Postnummer',
  Submit: 'Opret',
  SomethingWentWrongPaymentAccount:
    'Noget gik galt, mens vi prøvede at oprette betalingskonto. Prøv venligst igen.',
  saveDiscount: 'Spar',

  PleaseProvideNameEmailForAccountThisEmailInvoices:
    'Angiv navn og email til din betalingskonto, denne email bliver kun brugt til fakturaer og lignende.',
  ThankYouYourAccountingEmailHasBeenSet:
    'Tak skal du have! Din konto email er indstillet.',
  ContinueToCheckout: 'Fortsæt til checkout',
  Checkout: 'Checkout',
  AccountingEmail: 'Betalingskonto email',
  EmailMustBeAnEmailExSupportAtAcademondoCom:
    'Email skal være en email, f.eks. support@academondo.com.',
  ReceiveInvoice: 'Vil du modtage faktura?',
  ReceivePaymentConfirmation: 'Vil du modtage betalingsbekræftigelse?',
  ReceiveSubscriptionNotifications:
    'Vil du modtage notifikationer om dit abonnement?',
  SomethingWentWrongRecipientEmail:
    'Noget gik galt, mens vi prøvede at tilføje emailen til din betalingskonto. Prøv venligst igen.',

  PleaseSelectYourFormOfPayment: 'Vælg betalingsmetode.',
  ThankYouYourSubscriptionHasBeenSubmitted: 'Tak! Dit abonnement er oprettet.',
  YouAreNowReadyToExploreOurUniverse: `Du er nu klar til at bruge ${acaNames.dashboardWelcomeTitle}`,
  YouAlreadyHaveASubscriptionReachOut: `Du har allerede et abonnement, ræk ud support@academondo.com, hvis du stadig ikke har adgang.`,
  PaymentMethod: 'Betalingsmetode',
  PaymentCard: 'Betalingskort - ikke understøttet endnu',
  Invoice: 'Faktura',
  PaymentMethodMustBeSelected: 'Betalingsmetode skal vælges.',
  SomethingWentWrongPaymentMethod:
    'Noget gik galt, mens vi prøvede at oprette betalingsmetode. Prøv venligst igen.',

  // plan selector
  PlanSelectorTitle: acaNames.PlanSelectorTitle,
  PlanSelectorSubTitle: acaNames.PlanSelectorSubTitle,
  PlanSelectorParagraph1: acaNames.PlanSelectorParagraph1,
  PlanSelectorListItem1: acaNames.PlanSelectorListItem1,
  PlanSelectorListItem2: acaNames.PlanSelectorListItem2,
  PlanSelectorListItem3: acaNames.PlanSelectorListItem3,
  PlanSelectorListItem4: acaNames.PlanSelectorListItem4,

  // youtube video
  youtubeVideoInputTooltip: 'Indsæt et YouTube eller Vimeo link eller blot id',
  get youtubeVideoInputTooltipProfile() {
    return `Forstærk dit første indtryk med en 30 sekunders video, hvor du i bund og grund gentager din ${this.introductoryText}. Upload den som unlisted (ikke-søgbar) på YouTube/Vimeo og indsæt linket her.`;
  },
  youtubeVideoInputLabel: 'Video',
  youtubeVideoInput: 'https://youtube.com/... eller https://vimeo.com/id',

  // notes
  Notes: 'Noter',
  Comment: 'Kommentér',
  noteLabel: 'Skriv en note...',
  notePlaceholder: 'Eks. Simon siger...',
  noteText: 'Note',
  defaultError: 'Noget gik galt, prøv igen.',
  notesHint: 'Skrive en note',
  noteDeleteMsg: 'Er du sikker på at du vil slette denne note?',
  filterNotes: 'Filtrer noter',

  saveNoteHint: 'Brug Ctrl + Enter for at gemme',

  NewChat: 'Ny Chat',
  TypeYourMessageHere: 'Indtast din besked her...',

  // places
  place: `${acaNames.place}`,
  Place: `${acaNames.Place}`,
  newPlace: `Nyt ${acaNames.place}`,
  placeName: `${acaNames.Place} navn`,
  placesBuilder: `${acaNames.Place} bygger`,
  deletePlace: `Slet ${acaNames.Place}`,
  YouCanOnlySelect: 'Du kan kun vælge',
  placeType: `${acaNames.Place} type`,

  placesUrl2: acaNames.placesUrl2,

  PlacesTypes0: acaNames.PlacesTypes0,
  PlacesTypes1: acaNames.PlacesTypes1,
  PlacesTypes2: acaNames.PlacesTypes2,
  PlacesTypes3: acaNames.PlacesTypes3,

  Anonymous: 'Anonymous',
  Anonymise: 'Anonymise', // leave blank if no anonymity
  descOfAnonymous: `
    When you check this, your profile won't show your personal details. \n
    Only administrators and you will see the infomation.
  `,

  // new-user
  youHaveBeenInvitedToJoin: `Du er blevet inviteret til ${acaConfig.academondo}!`,

  copiedToClipboard: 'kopieret til udklipsholderen',
  Per: 'Per',
  Select: 'Vælg',

  '': '',

  VisibleForAll: 'Synlig for alle',
  get postTagsDescription() {
    return `Ved at vælge ${this.tags} kan du få ${common.yourPost} til at nå dete rette publikum.`;
  },

  get EditTags() {
    return `${this.Edit} ${this.tags}`;
  },
  get EditTagsDisclaimer() {
    return `Kontakt venligst Academondo, inden du begynder at ændre i dine ${this.tags}.
Ændringerne vil f.eks. ikke træde i kraft, før brugerne går ind på deres profil og ændrer deres ${this.tags} i den relevante kategori. Dog vil man stadig kunne søge efter det omdøbte tag og finde deres profil, men det gamle navn vil stadig være det, som brugeren valgte, da de valgte det.`;
  },
  // reusable words
  with: 'med',
  without: 'uden',
  toTag: 'tagge',
  tagged: 'tagget',
  Tagged: 'Tagget',
  tag: 'tag',
  Tag: 'Tag',
  tags: 'tags',
  Tags: 'Tags',
  postedIn: 'opslået i',
  hasCommentedOn: 'har kommenteret på',
  replyOnComment: 'svaret på kommentar',
  selectInApp: 'Vælg alle InApp',
  selectEmail: 'Vælg alle email',
  is: 'er',
  set: 'sat',
  to: 'til',
  To: 'Til',
  has: 'er',
  been: 'blevet',
  created: 'oprettet',
  Created: 'Oprettet',
  not: 'not',
  Number: 'Nummer',
  Info: 'Info',
  Toggle: 'Slå til/fra',
  Or: 'Eller',
  My: 'Mine',
  Leave: 'Forlad',
  and: 'og',
  Enable: 'Aktivér',
  enabled: 'aktiveret',
  Enabled: 'Aktiveret',
  disabled: 'deaktiveret',
  Disabled: 'Deaktiveret',
  read: 'læs',
  Read: 'Læs',

  get useTriggerCharacterToTagUsers() {
    return `Brug {0} for at ${this.toTag} ${this.users}`;
  },
  get TagUsers() {
    return `${this.Tag} ${this.users}`;
  },

  get YouHaveTaggedUsers() {
    return `Du har ${this.tagged}: {0}`;
  },

  enableTagUsersInPosts: acaConfig['enableTagUsersInPosts']
    ? acaConfig['enableTagUsersInPosts']
    : false,
  enableTagUsersInComments: acaConfig['enableTagUsersInComments']
    ? acaConfig['enableTagUsersInComments']
    : false,

  enableEventsForUnAuthedUsers: acaConfig['enableEventsForUnAuthedUsers']
    ? acaConfig['enableEventsForUnAuthedUsers']
    : false,
  enableCommunitiesForUnAuthedUsers: acaConfig[
    'enableCommunitiesForUnAuthedUsers'
  ]
    ? acaConfig['enableCommunitiesForUnAuthedUsers']
    : false,
  enableIdeasForUnAuthedUsers: acaConfig['enableIdeasForUnAuthedUsers']
    ? acaConfig['enableIdeasForUnAuthedUsers']
    : false,

  SeeAll: 'Se alle',

  // SSO
  SSOLoginIntro: `For at fortsætte, log på ${acaNames.dashboardWelcomeTitle}`,
  SSOLogin: `Fortsæt med ${acaConfig.academondo} konto`,
  SSOLoginOutro: `Fortsæt med email og kodeord`,
  SSOSignupIntro: `Opret dig gratis på ${acaNames.dashboardWelcomeTitle}`,
  SSOSignup: `Opret dig  med ${acaConfig.academondo} konto`,
  SSOSignupOutro: `Opret dig med email og kodeord`,
  ssoVerified: `Verificeret af ${acaConfig.academondo}`,

  // Linkedin SSO
  LinkedinLoginIntro: `For at fortsætte, log på med Linkedin`,
  LinkedinLogin: `Fortsæt med`,
  LinkedinContinue: `Fortsæt til`,
  LinkedinLoginOutro: `Fortsæt med email og kodeord`,
  LinkedinSignupIntro: `Opret dig på ${acaNames.dashboardWelcomeTitle}`,
  LinkedinSignup: `Opret dig med`,
  LinkedinSignupOutro: `Opret dig med email og kodeord`,

  // invite Email

  InviteExternal: 'Invitér en ven',
  InviteExternalToGroup:
    'Hvis du ønsker at invitere en kollega eller en ven, som ikke er en del af platformen',

  groupSenderText: `og har anbefalet dig at blive en del af {0}({1} on {2}). Så snart du har lavet en profil, så kan du tilgå {0} via dette link: {3}`,

  groupInitialInvitePersonalText: acaNames['groupInitialInvitePersonalText']
    ? acaNames['groupInitialInvitePersonalText']
    : `Tjek lige {0} på ${acaConfig.academondo}, jeg tror det er noget for dig`,
  initialInvitePersonalText: acaNames['initialInvitePersonalText']
    ? acaNames['initialInvitePersonalText']
    : ``,

  Hi: 'Hej',
  YouHaveBeenInvitedBy: 'Du er blevet inviteret af',
  toJoin: 'til at blive medlem af',

  YourInvitationTo: 'Din invitation til',
  hasBeenSent: 'er blevet sendt',

  change: 'skift',
  Change: 'Skift',
  suggested: 'foreslåede',
  Suggested: 'Foreslåede',
  Suggestions: 'Forslag',

  listOfSuggestionsEmpty: 'Listen af forslag er tom :-)',
  addSuggestionHelperText:
    'Venligst sørg for at gennemgå og ændre navnet og beskrivelsen, og husk at kontrollere for eventuelle duplikater eller stavefejl.',

  IncorrectFileFormat: 'ukorrekt filtype, venligst upload',
  FileSizeExceeds: 'Filstørrelse overgår',

  LoadEntities: 'Indlæs Enheder',
  ExportToExcel: 'Eksport til Excel',
  ExportToCsv: 'Eksportér som CSV',

  // Admin Page

  umamiKey: acaConfig['umamiKey'] ? acaConfig['umamiKey'] : false,
  umamiShareURL: acaConfig['umamiShareURL']
    ? acaConfig['umamiShareURL']
    : false,

  umamiAnalytics: 'Analytisk platform',

  UserAdministrationTitle: 'Bruger Adm.',
  CVsTitle: acaNames.CVs,
  CompaniesTitle: acaNames.Sites,
  JobsTitle: acaNames.Jobs,
  FenerumTitle: 'Subscriptions',
  StatisticsTitle: 'Tilbagetrækning',
  ExcelStatisticsTitle: 'Excel Statistik',
  PlacesTitle: `${acaNames.Places}`,
  AdminFunctionalityTitle: '⛔ Admin',
  NumberOfGroups: `Antal grupper`,
  NumberOfUsers: `Antal ${acaNames.users}`,
  NumberOfSimultaneousUsers: `Antal samtidige ${acaNames.users}`,
  SimultaneousUsers: `Samtidige ${acaNames.users}`,
  CurrentUserTypePieTitle: `Lagkage diagram - brugertyper`,
  CurrentGroupsTypePieTitle: `Lagkage diagram - grupper `,
  Grid: 'Tabel',
  Chart: 'Diagram',
  Charts: 'Diagrammer',
  UserLimiter: `Min. ${acaNames.users}`,
  PostLimiter: `Min. ${common.posts}`,
  GroupLimiter: `Min. grupper`,
  groupType: 'type',
  GroupType: 'Type',
  Reset: 'Nulstil',

  Show: 'Vis',

  RegisterNewCandidate: 'Kandidat',

  directMatch: 'Direkte Match',

  directMatchAdded: 'Direkte Match tilføjet',

  JobMatchDetails: 'Job match detaljer',

  EndDirectMatch: 'Afslut registrering',

  onlyOwnerCanCreatePosts: `${acaNames.Members} kan ikke oprette ${common.posts}`,

  onlyOwnerCanComment: `${acaNames.Member} kan ikke tilføje kommentarer`,

  // intro dialog - contact Consent
  helpheaderContactConsent:
    'Find den rigtige leverandør i dit område. I indkøbsservice sparer vi dig for en masse ressourcer ved at screene leverandøren for dig.',
  helpLineOneContactConsent:
    'Det er gratis at få et tilbud fra os og du vælger selv om du vil gøre brug af det.',
  helpLineTwoContactConsent:
    'Du vil blive kontaktet indenfor 48 timer med en potentiel løsning af din opgave.',

  contactConsentExplanation: 'Beskrivelse',

  imageLinkInput: 'https://imagelink.com/imageID',

  'Error: The email address is badly formatted.':
    'Fejl: email skal være en email.', // signup error msg bad email

  'Wrong email or password.': 'Forkert email eller kodeord.', // login error msg

  showRelatedJobs: `Vis ${acaNames.jobs}`,
  hideRelatedJobs: `Skjul`,

  result: 'resultat',
  results: 'resultater',

  endOfDatabase: 'Enden er nået',

  ThePasswordsMustMatch: 'Kodeordene skal være ens',

  dateFormat: acaConfig.dateFormat,
  date: 'Dato',
  datePrefix: 'kl.',
  aYear: 'et år',
  aMonth: 'en måned',
  day: 'dag',
  days: 'dage',
  aDay: 'en dag',
  hour: 'time',
  hours: 'timer',
  anHour: 'en time',
  minute: 'minut',
  minutes: 'minutter',
  aMinute: 'et minut',
  aFewSeconds: 'et par sekunder',
  seconds: 'sekunder',
  ago: ' siden',

  Monday: 'Mandag',
  Tuesday: 'Tirsdag',
  Wednesday: 'Onsdag',
  Thursday: 'Torsdag',
  Friday: 'Fredag',
  Saturday: 'Lørdag',
  Sunday: 'Søndag',

  January: 'Januar',
  February: 'Februar',
  March: 'Marts',
  April: 'April',
  May: 'Maj',
  June: 'Juni',
  July: 'Juli',
  August: 'August',
  September: 'September',
  October: 'Oktober',
  November: 'November',
  December: 'December',

  youHaveNoJobsCreateOneFromTheListOfCompanies: `Du har ikke oprettet ${acaNames.ajob} endnu - Opret her`,
  AllJobsAreNowPublished: `Alle ${acaNames.jobs} er nu offentlige`,

  Load: 'Indlæs',
  Remove: 'Fjern',
  Job: `${acaNames.Job}`,
  Jobs: `${
    acaNames.overwriteJobsButtonInSearch
      ? acaNames.overwriteJobsButtonInSearch
      : acaNames.Jobs
  }`,
  jobs: `${acaNames.jobs}`,
  RelatedJobs: `${acaNames.Jobs}`,

  showMembersPlugin: `Vis ${acaNames.members}`,
  showCalendarPlugin: 'Show calendar plugin',
  showFilesPlugin: 'Vis filer',
  showMapsPlugin: 'Vis kort',
  showForumPlugin: 'Tillad forum',
  get showGroupChatPlugin() {
    return `Tillad ${this.groupChat}`;
  },
  showEventsPlugin: `Tillad ${acaNames.events}`,
  showSubgroupsPlugin: `Tillad ${acaNames.subgroups}`,
  membersCanCreateEventsPlugin: `${acaNames.Members} kan oprette ${acaNames.events}`,
  hideFromSearchPlugin: `Skjul ${acaNames.idea} fra søgningen (Vigtig)`,
  canHideIdeaFromSearch: acaConfig.canHideIdeaFromSearch,
  membersCanCreateSubgroupsPlugin: `${acaNames.Members} kan oprette ${acaNames.subgroups}`,
  membersCanInvitePlugin: `${acaNames.Members} kan invitére`,

  registration: 'jobopslag',
  VisitRegistrations: 'Besøgsregistreringer',
  VisitRegistrationsStoredAutomatically:
    'Besøgsregistreringer gemmes automatisk',
  JobRegistrations: `Tidligere ${acaNames.jobs}`,
  RegisterVisitDialogText: `Registrering sendes til Admin,
    det vil ikke være muligt at ændre i din registreringen efterfølgende`,
  Send: 'Send',
  ThankYouForYourRegistrationOfVisitAt:
    'Tak for din registrering af besøget hos',

  // Dashboard
  showWidgets: 'Vis widgets',
  hideWidgets: 'Skjul widgets',
  dashboardWelcomeTitle: acaNames.dashboardWelcomeTitle,
  dashboardWelcomeSubTitle: acaNames.dashboardWelcomeSubTitle,
  dashboardWelcomeLinks: [
    // {
    //   text: 'Explore our services',
    //   link: 'https://ebrains.eu/#explore_anchor'
    // },
    // {
    //   text: `What is ${acaConfig.academondo}?`,
    //   link: `https://ebrains.eu/#What%20is%20EBRAINS?`
    // }
  ],

  // SubGroups

  subgroup: `${acaNames.subgroup}`,
  Subgroup: `${acaNames.Subgroup}`,
  subgroups: `${acaNames.subgroups}`,
  Subgroups: `${acaNames.Subgroups}`,

  inviteAllMembersToNewSubgroup: `invitér alle ${acaNames.members} til ${acaNames.yourNewSubgroup}`,

  // Events
  enableCountdownForEventDeadline: acaConfig['enableCountdownForEventDeadline']
    ? acaConfig['enableCountdownForEventDeadline']
    : false,
  AllUpcomingEvents: `Kommende ${acaNames.events}`,
  WaitingForAccept: 'Venter på accept',
  get WaitingForAcceptIdea() {
    return `${
      acaNames['WaitingForAcceptIdea']
        ? acaNames['WaitingForAcceptIdea']
        : this.WaitingForAccept
    }`;
  },
  get EventPinNotAllowed() {
    return `Du kan ikke ${this.Pin} "${this.private}" eller "${this.inviteOnly}" ${this.events} `;
  },
  get Pinned() {
    return `Pinned ${this.by} ${this.academondo}`;
  },
  Pin: 'Pin',
  UnPin: 'Unpin',
  SignupLink: 'Billet udbyder link',
  signupLinkExample: 'Ticketmaster.dk, EventBrite etc.',
  EventSignupLink: `Tilmeld dig her`,
  OnlineMeeting: 'Møde link',
  isOnlineEvent: `Online ${acaNames.event}`,
  Online: `Online`,
  MeetingLink: 'Møde link',
  meetingLinkExample: 'Zoom, Teams, Meet, Jitsi etc.',
  DiscountCode: `Rabatkode`,
  discountCodeExample: `Indtast rabatkode for ${acaNames.members}`,
  TaskDeadline: `Deadline`,
  get taskDeadlineHint() {
    return `${this.Task} deadline`;
  },
  Deadline: `Tidsfrist`,
  DeadlineCountdown: `Tidsfrist m/nedtælling`,
  EventDeadline: `Tilmeldingsfrist`,
  eventDeadlineHint: `Tilmeldingsfrist`,
  PleaseAddToYourCalendarButtonText: `Tilføj til din kalender`,
  gotoEvents: `Gå til ${acaNames.events}`,
  events: acaNames.events,
  event: acaNames.event,
  _events: acaNames.event,
  Events: acaNames.Events,
  SubEvents: `Under${acaNames.events}`,
  Event: acaNames.Event,
  EventTitle: acaNames.EventsTitle,

  PastEvents: `Tidligere ${acaNames.events}`,
  UpComingEvents: `Kommende ${acaNames.events}`,
  Discover: acaNames['Discover'] ? acaNames['Discover'] : 'Find',
  initialEventName: `${acaNames.newEvent}`,
  newEvent: `${acaNames.newEvent}`,
  eventsSubTitle: '',
  deleteEvent: `Slet ${acaNames.event}`,
  thisEvent: acaNames.thisevent,
  DeleteEvent: `Slet ${acaNames.event}`,
  PublishDraftEvent: `Gem kladden`,
  eventStartStep1: `Velkommen til ${acaNames.event}byggeren, følg disse skridt for at oprette ${acaNames.yourEvent}.`,
  eventStartStep2: `${acaNames.Theevent} vil være synligt baseret på indstillingerne.`,
  beginBuildingYourEvent: `Byg ${acaNames.yourEvent}`,
  typeOfEvent: `Tags`,
  PublishEvent: `${acaNames.Publish} ${acaNames.event}`,
  locationOfEvent: `Den fysiske adresse af ${acaNames.thisevent}`,
  eventName: `${acaNames.eventName}`,
  EventName: `${acaNames.EventName}`,
  writeInformationAboutThePurposeOfThisEvent: `Beskrivelse af ${acaNames.theevent}`,
  inviteOnlyEventDesc: `Kun synligt for inviterede ${acaNames.members}. Kun inviterede ${acaNames.members} kan deltage.`,
  PrivateEventDesc: `Synligt for alle, men man skal godkendes af en ${acaNames.Manager} for at deltage.`,
  OpenPrivateEventDesc: `Synligt for alle, alle kan deltage`,
  PublicEventDesc: `Synligt for alle, alle kan deltage`,
  YourEvents: `${acaNames.YourEvents}`,
  permissionsForEvent: `Vælg hvad der skal være muligt for ${acaNames.members} af ${acaNames.theevent}`,
  get publishEventDesc() {
    return `Ved at inkludere ${this.tags} inden oprettelsen, sendes notifikationer direkte til relevante ${this.users}.`;
  },
  inviteTo_events: `Invitér til ${acaNames.event}`,
  MissingCanCreateDraftEventMsg: acaNames['MissingCanCreateDraftEventMsg']
    ? acaNames['MissingCanCreateDraftEventMsg']
    : `Hvis du ønsker at oprette ${acaNames.events}, ræk ud til ${acaConfig.supportMail} for mere info`,
  MissingCanPublishEventsMsg: `Du kan ikke ${acaNames.toPublish} ${acaNames.events}, ræk ud til ${acaConfig.supportMail} for mere info`,
  attending: 'Deltager',
  declined: 'Afvist',
  maybe: 'Måske',
  interested: 'Interesseret',
  YouHaveReceivedAn: 'Du har modtaget et',
  hasInvitedYouTo: 'har inviteret dig til',
  join: 'blive en del af',
  from: 'fra',
  From: 'Fra',
  Dear: 'Kære',
  inviteName: 'Navn på kontakt',
  personalText: 'Personlig hilsen',
  noReply: 'Intet svar',
  attending_action: 'Deltag',
  declined_action: 'Afvis',
  maybe_action: 'Måske',
  interested_action: 'Interesseret',
  noReply_action: 'Intet svar',
  attendance: 'Deltagelse',
  selectAttendance: 'Vælg deltagelse',
  AllYourPostsFromXWillBeDeleted: `Alle ${common.yourPosts} fra {0} vil blive slettet`,
  StartDateReq: `Start dato krævet`,
  EndDateReq: `Slut dato krævet`,
  youHaveBeenInvitedToEvent: `Du er inviteret`,
  youHaveDeclinedEvent: `Du har afvist`,
  whoCanCreateEventsMessage: `${acaNames.Manager} og ejer kan oprette ${acaNames.community} ${acaNames.events}`,

  hideAttending: acaConfig['hideAttending'] ? true : false,
  hideMaybe: acaConfig['hideMaybe'] ? true : false,

  NetworkCVStep: 'Invitér dit netværk',
  Home: acaNames.dashboardTitle,

  LeaveRemovePostsSpecialButton: `Forlad og slet ${common.posts}`,

  // event search Settings
  hidePast: `Skjul gamle`,
  hideStarted: `Skjul startede`,
  showOngoing: `Vis nuværende`,
  Expired: `Udløbet`,
  showExpired: `Vis udløbne`,
  show24Hours: 'Sidste 24 timer',
  show1Week: 'Sidste uge',
  show4Weeks: 'Sidste 4 uger',
  showAll: `Vis alle`,
  customRange: `Tilpasset periode`,

  // image-cropper
  rotateLeft: 'Rotatér venstre',
  rotateRight: 'Rotatér højre',
  zoomIn: 'Zoom ind',
  zoomOut: 'Zoom ud',
  resetImage: 'Nulstil',
  saveImage: 'Gem billede',
  MoveTheGridToCropImage: 'Bevæg gitteret for at beskære',
  AspectRatioLocked: 'Aspect ratio: låst',

  // Ideas
  ideaGenerelStepDescription: acaNames['ideaGenerelStepDescription']
    ? acaNames['ideaGenerelStepDescription']
    : '',
  ideaMediaStepDescription: acaNames['ideaMediaStepDescription']
    ? acaNames['ideaMediaStepDescription']
    : '',
  ideaTagStepDescription: acaNames['ideaTagStepDescription']
    ? acaNames['ideaTagStepDescription']
    : '',
  Trainee: 'Trainee',
  FinishCoupling: 'Afslut registrering',
  FinishCouplingHint: `Du kan afslutte registreringen når alle trainees har fået tilknyttet 2 ${acaNames.jobs}`,
  jobUsedTooManyTimeInCouplings: 'Brugt for mange gange',
  couplingJobFirstContact: 'Første kontakt',
  jobUsagesInThisCoupling: `Benyttet på dette ${acaNames.idea}`,
  jobUsagesInAllCouplings: `Benyttet på tværs af alle ${acaNames.ideas}`,
  DisplayName: 'Fulde navn',
  Matched: 'Matched',
  MatchWithTrainee: 'Match med trainee',
  ACCoupling: 'T-forløb',
  ACCouplingTrainees: 'T-forløb trainees',
  dateOfAction: 'Starttidspunkt for indsats',
  dateOfActionEnd: 'Sluttidspunkt for indsats',
  visitationDate: 'Visitationdato',
  dateOfPublication: 'Dato for formidling',
  responsibleOrg: 'Ansvarlig organisation/A-kasse',
  'trainee-no-status': '🔴',
  'trainee-progress': '🟡',
  'trainee-done': '🟢',
  IdeasIcon: acaNames.IdeasIcon,
  copyEvent: acaConfig.copyEvent,
  copyCommunity: acaConfig.copyCommunity,
  copyIdea: acaConfig.copyIdea,
  gotoIdea: `Gå til ${acaNames.ideas}`,
  initialIdeaName: acaNames.initialIdeaName,
  idea: acaNames.idea,
  _ideas: acaNames.idea,
  Idea: acaNames.Idea,
  ideas: acaNames.ideas,
  Ideas: acaNames.Ideas,
  OpenIdeas: `Åbne ${acaNames.ideas}`,
  OpenCommunities: `Åbne ${acaNames.communities}`,
  IdeasTitle: acaNames.IdeasTitle,
  ideasSubTitle: '',
  typeOfIdea: acaNames.Category5 ? `Tags og ${acaNames.category5}` : `Tags`,
  writeInformationAboutThePurposeOfThisIdea: acaNames[
    'defaultIdeaDescriptionHelperText'
  ]
    ? acaNames['defaultIdeaDescriptionHelperText']
    : `Beskrivelse af ${acaNames.idea}`,
  locationOfIdea: `Den fysiske adresse af ${acaNames.idea}`,
  ideaStartStep1: acaNames.ideaStartStep1,
  ideaStartStep2: acaNames.ideaStartStep2,
  beginBuildingYourIdea: `Byg ${acaNames.yourIdea}`,
  newIdea: `Start`,
  permissionsForIdea: `Vælg hvad der skal være muligt for dine ${acaNames.members}`,
  inviteOnlyIdeaDesc: `Kun synligt for inviterede ${acaNames.members}. Kun inviterede ${acaNames.members} kan deltage.`,
  PublicIdeaDesc: `Synligt for alle, alle kan deltage.`,
  OpenPrivateIdeaDesc: `Synligt for alle, alle kan deltage og ${common.posts} er søgbare.`,
  PrivateIdeaDesc: `Synligt for alle, men man skal godkendes af en ${acaNames.Manager} for at deltage`,
  publishIdea: `Gem ${acaNames.idea}`,
  get publishIdeaDesc() {
    return `Ved at inkludere ${this.tags} inden oprettelsen, sendes notifikationer direkte til relevante ${this.users}.`;
  },
  deleteIdea: `Slet ${acaNames.idea}`,
  confirmDeleteIdea: `Er du sikker på at du vil slette ${acaNames.yourIdea}?`,
  inviteTo_ideas: `Invitér til ${acaNames.idea}`,
  PublicIdea: `${acaNames.Public}`,
  PrivateIdea: `${acaNames.Private}`,
  InviteOnlyIdea: `${acaNames.InviteOnly}`,
  FilterIdeas: `Filtrer...`,
  NoMatchingIdeas: `Ingen hits...`,
  ideaName: acaNames.ideaName,
  IdeaName: acaNames.IdeaName,
  IdeasYouManage: `${acaNames.Ideas} du bestyrer`,
  YourIdeas: `${acaNames.YourIdeas}`,
  DeleteIdea: `Slet ${acaNames.idea}`,
  PublishDraftIdea: `Gem kladden`,
  MissingCanCreateDraftIdeaMsg: `Hvis du ønsker at oprette ${acaNames.ideas}, ræk ud til ${acaConfig.supportMail} for mere info`,
  MissingCanPublishIdeaMsg: `Du kan ikke gemme ${acaNames.ideas}, ræk ud til ${acaConfig.supportMail} for mere info`,
  defaultIdeaDescription: acaNames['defaultIdeaDescription']
    ? acaNames['defaultIdeaDescription']
    : '',
  showAdressSelectorInIdeas: acaNames.showAdressSelectorInIdeas,
  showWebsiteInIdeas: acaNames.showWebsiteInIdeas,
  websiteCollaboratoryUrl: acaNames.websiteCollaboratoryUrl,

  // Communities
  enableEvents: acaConfig.enableEvents,
  enableCommunities: acaConfig.enableCommunities,
  gotoCommunity: `Gå til ${acaNames.communities}`,
  initialCommunityName: acaNames.initialCommunityName,
  initialSubCommunityName: acaNames.newSubgroup,
  community: acaNames.community,
  _communities: acaNames.community,
  Community: acaNames.Community,
  Communitys: acaNames.Communities,
  communities: acaNames.communities,
  Communities: acaNames.Communities,
  CommunitiesTitle: acaNames.communitiesTitle,
  communitiesSubTitle: '',
  media: 'Medier',
  typeOfCommunity: `Tags`,
  uploadCoverImage: 'Upload cover billede',
  coverImage: 'Cover billede',
  writeInformationAboutThePurposeOfThisCommunity: `Beskrivelse af ${acaNames.community}`,
  locationOfCommunity: `Den fysiske adresse af ${acaNames.community}`,
  communityStartStep1: `Velkommen til ${acaNames.community}byggeren, følg disse steps for at oprette ${acaNames.community}.`,
  communityStartStep2: `${acaNames.Community}et vil være synligt baseret på baggrund af indstillingerne.`,
  beginBuildingYourCommunity: `Byg ${acaNames.community}`,
  newCommunity: `Nyt ${acaNames.community}`,
  privacySettings: 'Privatliv og Indstillinger',
  Settings: 'Indstillinger',
  permissionsForCommunity: `Vælg hvad der skal være muligt for dine ${acaNames.members}`,
  inviteOnlyCommunityDesc: `Kun synligt for inviterede ${acaNames.members}. Kun inviterede ${acaNames.members} kan deltage.`,
  PublicCommunityDesc: `Synligt for alle, alle kan deltage, ${common.posts} er søgbare og vises på alles væg.`,
  OpenPrivateCommunityDesc: `Synligt for alle, alle kan deltage og ${common.posts} er søgbare.`,
  PrivateCommunityDesc: `Synligt for alle, men man skal godkendes af en ${acaNames.Manager} for at deltage.`,
  publishCommunity: `Gem ${acaNames.community}`,
  get publishCommunityDesc() {
    return `Ved at inkludere ${this.tags} inden oprettelsen, sendes notifikationer direkte til relevante ${this.users}.`;
  },
  deleteCommunity: `Slet ${acaNames.community}`,
  deleteSubGroup: `Slet ${acaNames.subgroup}`,
  confirmDeleteCommunity: `Er du sikker på at du vil slette dette ${acaNames.community}?`,
  Owner: 'Ejer',
  Drafting: 'Kladde',
  Moderator: `${acaNames.Moderator}`,
  Administrator: `${acaNames.Manager}`,
  groupRelation: 'Medlemskab',
  groupRelations: 'Medlemskab',
  PromoteToModerator: `Forfrem til ${acaNames.Moderator}`,
  PromoteToAdministrator: `Forfrem til ${acaNames.Manager}`,
  DowngradeToMember: `Gør til ${acaNames.Member}`,
  RemoveMember: `Slet ${acaNames.Member}`,
  RemoveMemberAndRemovePosts: `Slet ${acaNames.Member} og ${common.posts}`,
  inviteTo_communities: `Invitér til ${acaNames.community}`,
  PublicCommunity: `${acaNames.Public} ${acaNames.Community}`,
  PrivateCommunity: `${acaNames.Private} ${acaNames.Community}`,
  InviteOnlyCommunity: `${acaNames.InviteOnly} ${acaNames.Community}`,
  FilterCommunities: `Filtrer...`,
  NoMatchingCommunities: `Ingen hits...`,
  PendingInvites: 'Invitationer',
  GoToSearch: 'Gå til søg',
  Drafts: 'Kladder',
  Update: 'Opdatér',
  UpdateAvailable: `🚀 Ny version klar`,
  publish: acaNames.Publish,
  Publish: acaNames.Publish,
  PublishPost: 'Opslå',
  communityName: `${acaNames.community} navn`,
  CommunityName: `${acaNames.Community} navn`,
  CommunitiesYouManage: `${acaNames.Communities} du bestyrer`,
  YourCommunities: `${acaNames.YourCommunities}`,
  YourPremiumCommunities: acaNames['YourPremiumCommunities']
    ? acaNames['YourPremiumCommunities']
    : acaNames.YourCommunities,
  premiumCommunity: acaNames['premiumCommunity']
    ? acaNames['premiumCommunity']
    : acaNames.community,
  PremiumCommunity: acaNames['PremiumCommunity']
    ? acaNames['PremiumCommunity']
    : acaNames.Community,
  premiumCommunities: acaNames['premiumCommunities']
    ? acaNames['premiumCommunities']
    : acaNames.communities,
  PremiumCommunities: acaNames['PremiumCommunities']
    ? acaNames['PremiumCommunities']
    : acaNames.Communities,
  EnablePremiumCommunities: acaConfig['EnablePremiumCommunities']
    ? acaConfig['EnablePremiumCommunities']
    : false,
  get PremiumDesc1() {
    return `Bliv medlem af ${acaConfig.academondo} ${this.Full} og få adgang til vores tre ${this.premiumCommunities}. Her får du nyeste viden, værktøjer, eksperter og netværk, så I kan vende bæredygtighed til business i tide.`;
  },
  get YouAreNotPremiumUserYet() {
    return `Ræk ud for mere info på `;
  },
  YouDontHaveAccessToPremiumContent: `Du har ikke adgang til dette premium indhold`,
  ThisIs: 'Dette er ',
  Premium: 'Premium',
  premium: 'premium',
  premiumBackgroundColor: 'Baggrundsfarve til widget',
  premiumBackgroundColorPlaceholder: '#ffffff',
  premiumBackgroundColorHint: 'Indsæt valid css for background-color',
  by: 'af',
  By: 'Af',
  OnBehalfOf: 'i',
  ThisIsHowYourPostWillLook: `Således vil ${common.yourPost} se ud`,
  GoTo: 'Gå til',
  ViewAll: 'Vis alle',
  Comments: 'Kommentarer',
  NumberOfComments: 'Antal kommentarer',
  NumberOfCommentsSubTitle: 'Viser kun datoer med mere end 1 kommentar',
  LatestComment: 'Seneste kommentar',
  MakeAComment: 'Lav en kommentar',
  DeleteCommunity: `Slet ${acaNames.community}`,
  PublishDraftCommunity: `Gem kladden`,
  MissingCanCreateDraftCommunityMsg: `Hvis du ønsker at oprette ${acaNames.communities}, ræk ud til ${acaConfig.supportMail} for mere info`,
  MissingCanPublishCommunityMsg: `Du kan ikke gemme ${acaNames.communities}, ræk ud til ${acaConfig.supportMail} for mere info`,
  LoadAllComments: 'Indlæs alle kommentarer',
  PrivacyCanOnlyBeChangedOnCreation: `❗ Privatliv kan ikke ændres, efter det er ${acaNames.published}`,

  MissingCanCreateDraftGroupMsg: `Hvis du ønsker at oprette, ræk ud til ${acaConfig.supportMail} for mere info`,
  MissingCanPublishGroupMsg: `Du kan ikke ${acaNames.toPublish}, ræk ud til ${acaConfig.supportMail} for mere info`,

  // group
  get groupTagsDescription() {
    return `Når du opretter ${this.openPrivate} {0}, har du mulighed for at tilføje ${this.tags}. Disse ${this.tags} fungerer som filtre og sikrer, at ${this.users}, der har valgt mindst ét af de samme ${this.tags}, modtager en notifikation.`;
  },
  showMemberFilterCategory4: acaConfig['showMemberFilterCategory4']
    ? acaConfig['showMemberFilterCategory4']
    : false,
  showMemberFilterCategory5: acaConfig['showMemberFilterCategory5']
    ? acaConfig['showMemberFilterCategory5']
    : false,
  enableGroupRolesForCommunity: acaConfig['enableGroupRolesForCommunity']
    ? acaConfig['enableGroupRolesForCommunity']
    : false,
  enableGroupRolesForEvent: acaConfig['enableGroupRolesForEvent']
    ? acaConfig['enableGroupRolesForEvent']
    : false,
  enableGroupRolesForIdea: acaConfig['enableGroupRolesForIdea']
    ? acaConfig['enableGroupRolesForIdea']
    : false,
  groupRole: 'rolle',
  GroupRole: 'Rolle',
  groupRoles: 'roller',
  GroupRoles: 'Roller',
  get GroupRoleLabel() {
    return `${this.Select} ${this.groupRole}`;
  },
  get RemoveGroupRole() {
    return `${this.Remove} ${this.groupRole}`;
  },
  get RemainingMembers() {
    return acaNames['RemainingMembers']
      ? acaNames['RemainingMembers']
      : this.Members;
  },
  groupRole0: acaNames['groupRole0'] ? acaNames['groupRole0'] : '',
  groupRoles0: acaNames['groupRoles0'] ? acaNames['groupRoles0'] : '',
  groupRoles0Hint: acaNames['groupRoles0Hint']
    ? acaNames['groupRoles0Hint']
    : '',

  groupRoles0Link: acaNames['groupRoles0Link']
    ? acaNames['groupRoles0Link']
    : '',

  groupRoles0Dialog: acaNames['groupRoles0Dialog']
    ? acaNames['groupRoles0Dialog']
    : '',
  groupRoles0Tooltip: acaNames['groupRoles0Tooltip']
    ? acaNames['groupRoles0Tooltip']
    : '',
  groupRole1: acaNames['groupRole1'] ? acaNames['groupRole1'] : '',
  groupRoles1: acaNames['groupRoles1'] ? acaNames['groupRoles1'] : '',
  groupRoles1Hint: acaNames['groupRoles1Hint']
    ? acaNames['groupRoles1Hint']
    : '',

  groupRoles1Link: acaNames['groupRoles1Link']
    ? acaNames['groupRoles1Link']
    : '',

  groupRoles1Dialog: acaNames['groupRoles1Dialog']
    ? acaNames['groupRoles1Dialog']
    : '',
  groupRoles1Tooltip: acaNames['groupRoles1Tooltip']
    ? acaNames['groupRoles1Tooltip']
    : '',
  groupRole2: acaNames['groupRole2'] ? acaNames['groupRole2'] : '',
  groupRoles2: acaNames['groupRoles2'] ? acaNames['groupRoles2'] : '',
  groupRoles2Hint: acaNames['groupRoles2Hint']
    ? acaNames['groupRoles2Hint']
    : '',

  groupRoles2Link: acaNames['groupRoles2Link']
    ? acaNames['groupRoles2Link']
    : '',

  groupRoles2Dialog: acaNames['groupRoles2Dialog']
    ? acaNames['groupRoles2Dialog']
    : '',
  groupRoles2Tooltip: acaNames['groupRoles2Tooltip']
    ? acaNames['groupRoles2Tooltip']
    : '',
  groupRole3: acaNames['groupRole3'] ? acaNames['groupRole3'] : '',
  groupRoles3: acaNames['groupRoles3'] ? acaNames['groupRoles3'] : '',
  groupRoles3Hint: acaNames['groupRoles3Hint']
    ? acaNames['groupRoles3Hint']
    : '',

  groupRoles3Link: acaNames['groupRoles3Link']
    ? acaNames['groupRoles3Link']
    : '',

  groupRoles3Dialog: acaNames['groupRoles3Dialog']
    ? acaNames['groupRoles3Dialog']
    : '',
  groupRoles3Tooltip: acaNames['groupRoles3Tooltip']
    ? acaNames['groupRoles3Tooltip']
    : '',
  groupRole4: acaNames['groupRole4'] ? acaNames['groupRole4'] : '',
  groupRoles4: acaNames['groupRoles4'] ? acaNames['groupRoles4'] : '',
  groupRoles4Hint: acaNames['groupRoles4Hint']
    ? acaNames['groupRoles4Hint']
    : '',

  groupRoles4Link: acaNames['groupRoles4Link']
    ? acaNames['groupRoles4Link']
    : '',

  groupRoles4Dialog: acaNames['groupRoles4Dialog']
    ? acaNames['groupRoles4Dialog']
    : '',
  groupRoles4Tooltip: acaNames['groupRoles4Tooltip']
    ? acaNames['groupRoles4Tooltip']
    : '',
  groupRole5: acaNames['groupRole5'] ? acaNames['groupRole5'] : '',
  groupRoles5: acaNames['groupRoles5'] ? acaNames['groupRoles5'] : '',
  groupRoles5Hint: acaNames['groupRoles5Hint']
    ? acaNames['groupRoles5Hint']
    : '',

  groupRoles5Link: acaNames['groupRoles5Link']
    ? acaNames['groupRoles5Link']
    : '',

  groupRoles5Dialog: acaNames['groupRoles5Dialog']
    ? acaNames['groupRoles5Dialog']
    : '',
  groupRoles5Tooltip: acaNames['groupRoles5Tooltip']
    ? acaNames['groupRoles5Tooltip']
    : '',
  groupRoleMember: acaNames['groupRoleMember']
    ? acaNames['groupRoleMember']
    : '',
  groupRolesMember: acaNames['groupRolesMember']
    ? acaNames['groupRolesMember']
    : '',
  groupRolesMemberHint: acaNames['groupRolesMemberHint']
    ? acaNames['groupRolesMemberHint']
    : '',
  groupRolesMemberLink: acaNames['groupRolesMemberLink']
    ? acaNames['groupRolesMemberLink']
    : '',

  groupRolesMemberDialog: acaNames['groupRolesMemberDialog']
    ? acaNames['groupRolesMemberDialog']
    : '',
  groupRolesMemberTooltip: acaNames['groupRolesMemberTooltip']
    ? acaNames['groupRolesMemberTooltip']
    : '',
  groupRoleNoRole: acaNames['groupRoleNoRole']
    ? acaNames['groupRoleNoRole']
    : '',
  groupRolesNoRole: acaNames['groupRolesNoRole']
    ? acaNames['groupRolesNoRole']
    : '',
  groupRolesNoRoleHint: acaNames['groupRolesNoRoleHint']
    ? acaNames['groupRolesNoRoleHint']
    : '',
  groupRolesNoRoleLink: acaNames['groupRolesNoRoleLink']
    ? acaNames['groupRolesNoRoleLink']
    : '',

  groupRolesNoRoleDialog: acaNames['groupRolesNoRoleDialog']
    ? acaNames['groupRolesNoRoleDialog']
    : '',
  groupRolesNoRoleTooltip: acaNames['groupRolesNoRoleTooltip']
    ? acaNames['groupRolesNoRoleTooltip']
    : '',

  // Forum
  ViewsPosts: 'Visninger',
  Share: 'Del',
  ShareCopyLink: 'Del dette link',
  ShareLinkOnX: 'Del dette link på X',
  Preview: 'Vis',
  GroupPublicPost: `${common.Post}`,
  GroupPublicPosts: `${
    acaNames.overwritePostsButtonInSearch
      ? acaNames.overwritePostsButtonInSearch
      : common.Posts
  }`,
  discardChangesPostCreateTitle: `Kassér ${common.post}?`,
  discardChangesPostUpdateTitle: 'Kassér ændringerne?',
  discardChangesPostCreateBody:
    'Er du sikker på at du vil afslutte, det indtastede forsvinder?',
  discardChangesPostUpdateBody:
    'Er du sikker på at du vil afslutte denne opdatering, det indtastede forsvinder?',
  deletePostTitle: `Slet ${common.post}?`,
  deletePostBody: `${common.ThePost} og alle kommentarer bliver slettet, er du sikker?`,
  attachments: 'vedhæftelser',
  Attachments: 'Vedhæftelser',
  newComment: 'Ny Kommentar',

  CanLeaveCVTitle: 'You need to save to make the profile public',
  CanLeaveCVText: 'Do you want to leave or publish your changes',
  CanLeaveCVHint: '',
  PublishCV: `Gem ${acaNames.cv}`,
  LeaveCvBuilder: `Forlad uden at gemme`,

  // reactions
  ReactionLike: acaNames.ReactionLike,
  ReactionLikeFull: acaNames.ReactionLikeFull,
  ReactionLikeText: acaNames.ReactionLikeText,
  ReactionFavorite: acaNames.ReactionFavorite,
  ReactionFavoriteFull: acaNames.ReactionFavoriteFull,
  ReactionFavoriteText: acaNames.ReactionFavoriteText,
  ReactionRecycling: acaNames.ReactionRecycling,
  ReactionRecyclingFull: acaNames.ReactionRecyclingFull,
  ReactionRecyclingText: acaNames.ReactionRecyclingText,
  ReactionDislike: acaNames.ReactionDislike,
  ReactionDislikeFull: acaNames.ReactionDislikeFull,
  ReactionDislikeText: acaNames.ReactionDislikeText,
  ReactionNeutral: acaNames.ReactionNeutral,
  ReactionNeutralFull: acaNames.ReactionNeutralFull,
  ReactionNeutralText: acaNames.ReactionNeutralText,
  ReactionAngry: acaNames.ReactionAngry,
  ReactionAngryFull: acaNames.ReactionAngryFull,
  ReactionAngryText: acaNames.ReactionAngryText,

  // map
  showResultsOnMap: 'Vis på kort',
  showResultsAsList: 'Vis som liste',
  mapSiteTooltip: acaNames.mapSiteTooltip,
  mapSiteHint: acaNames.mapSiteHint,
  u0Marker: acaConfig.u0Marker,
  siteMarker: acaConfig.siteMarker,
  u2Marker: acaConfig.u2Marker,
  u3Marker: acaConfig.u3Marker,
  u4Marker: acaConfig.u4Marker,
  u5Marker: acaConfig.u5Marker,
  gMarker: acaConfig.gMarker,
  cMarker: acaConfig.cMarker,
  eMarker: acaConfig.eMarker,
  iMarker: acaConfig.iMarker,
  nMarker: acaConfig.nMarker,
  p0Marker: acaConfig.p0Marker,
  p1Marker: acaConfig.p1Marker,
  p2Marker: acaConfig.p2Marker,
  p3Marker: acaConfig.p3Marker,
};
