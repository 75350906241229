import { Injectable } from '@angular/core';
import { MondoError, MondoErrorTypes } from '../shared/models/MondoError';
import { FirebaseError } from 'firebase/app';

@Injectable()
export class ErrorService {
  constructor() {}

  public parseToMondoError(error: FirebaseError): MondoError {
    const errorType = this.getErrorType(error);
    return new MondoError(
      error.message,
      errorType,
      this.getDisplayText(errorType)
    );
  }

  private getDisplayText(errorType: MondoErrorTypes): string {
    switch (errorType) {
      case MondoErrorTypes.USER_NOT_VERIFIED:
        return 'theGivenEmailIsNotVerified';
      case MondoErrorTypes.EMAIL_ALREADY_EXISTS:
        return 'thatEmailAlreadyExists';
      case MondoErrorTypes.WRONG_EMAIL_OR_PASSWORD:
        return 'wrongEmailOrPassword';
      case MondoErrorTypes.INVALID_EMAIL:
        return 'wrongEmail';
      default:
        return 'unknownError';
    }
  }

  private getErrorType(error: FirebaseError): MondoErrorTypes {
    switch (error.code) {
      case 'auth/user-not-found':
        return MondoErrorTypes.WRONG_EMAIL_OR_PASSWORD;
      case 'auth/invalid-password':
        return MondoErrorTypes.WRONG_EMAIL_OR_PASSWORD;
      case 'auth/wrong-password':
        return MondoErrorTypes.WRONG_EMAIL_OR_PASSWORD;
      case 'auth/email-already-exists':
        return MondoErrorTypes.EMAIL_ALREADY_EXISTS;
      case 'auth/email-not-verified':
        return MondoErrorTypes.USER_NOT_VERIFIED;
      case 'auth/invalid-email':
        return MondoErrorTypes.INVALID_EMAIL;
      default:
        return MondoErrorTypes.UNKNOWN;
    }
  }
}
