import { PersonalDetails } from './personal-details';
import { Intro } from './intro';
import { Network } from '../../../stepper/cv/model/network';
import { Experience } from './experience';
import { ItemType } from '../../../stepper/shared/model/ItemType';
import { IBaseStepItem } from '../../../stepper/shared/model/IBaseStepItem';
import { StatusContext } from '../../../stepper/shared/model/mondo-status/status-context';
import { AdditionalInfo } from './additional-info';
import { Languages } from '../languages/languages';

export class CV implements IBaseStepItem, JsonCv<Date> {
  public readonly type = ItemType.CV;
  public static fromJson(
    {
      name,
      created,
      ownerId,
      lastUpdate,
      madePublic,
      personalDetails,
      intro,
      experience,
      languages,
      additionalInfo,
      network,
      experience0,
      experience1,
      experience2,
      experience3,
      experience4,
      experience5,
      expireWarned,
    }: JsonCv<number>,
    key: string,
    status: StatusContext = new StatusContext()
  ): CV {
    return new CV(
      status,
      name,
      ownerId,
      key,
      created ? new Date(created) : null,
      lastUpdate ? new Date(lastUpdate) : null,
      madePublic ? new Date(madePublic) : null,
      PersonalDetails.fromJson(personalDetails || {}),
      Intro.fromJson(intro || {}),
      Experience.fromJson(experience || {}, key),
      Languages.fromJson(languages || {}, key),
      AdditionalInfo.fromJson(additionalInfo || {}),
      Network.fromJson(network || {}),
      experience0 || 0,
      experience1 || 0,
      experience2 || 0,
      experience3 || 0,
      experience4 || 0,
      experience5 || 0,
      expireWarned || false
    );
  }

  public static toJson(cv: CV): JsonCv<number> {
    return {
      name: cv.name,
      ownerId: cv.ownerId,
      key: cv.key,
      created: cv.created ? cv.created.getTime() : null,
      lastUpdate: cv.lastUpdate ? cv.lastUpdate.getTime() : null,
      madePublic: cv.madePublic ? cv.madePublic.getTime() : null,
      personalDetails: PersonalDetails.toJson(cv.personalDetails),
      intro: cv.intro,
      experience: Experience.toJson(cv.experience),
      languages: Languages.toJson(cv.languages),
      additionalInfo: cv.additionalInfo,
      network: cv.network,
      experience0: cv.experience0,
      experience1: cv.experience1,
      experience2: cv.experience2,
      experience3: cv.experience3,
      experience4: cv.experience4,
      experience5: cv.experience5,
      expireWarned: cv.expireWarned,
    };
  }

  constructor(
    public status: StatusContext = new StatusContext(),
    public name = '',
    public ownerId = '',
    public key = '',
    public created: Date = null,
    public lastUpdate: Date = null,
    public madePublic: Date = null,
    public personalDetails = new PersonalDetails(),
    public intro: Intro = new Intro(),
    public experience: Experience = new Experience(),
    public languages: Languages = new Languages(),
    public additionalInfo: AdditionalInfo = new AdditionalInfo(),
    public network: Network = new Network(),
    public experience0 = 0,
    public experience1 = 0,
    public experience2 = 0,
    public experience3 = 0,
    public experience4 = 0,
    public experience5 = 0,
    public expireWarned = false
  ) {}
}

interface JsonCv<T> {
  name: string;
  ownerId: string;
  key: string;
  created: T;
  lastUpdate: T;
  madePublic: T;
  personalDetails;
  intro;
  experience;
  languages;
  additionalInfo;
  network;
  experience0: number;
  experience1: number;
  experience2: number;
  experience3: number;
  experience4: number;
  experience5: number;
  expireWarned: boolean;
}
