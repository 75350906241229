import { DataConstants } from '../app/shared/consts/dataConstants';

const region:
  | 'us-central1'
  | 'us-east1'
  | 'us-east4'
  | 'europe-west1'
  | 'europe-west2'
  | 'asia-east2'
  | 'asia-northeast1' = 'europe-west1';
const firebase = {
  apiKey: 'AIzaSyB7DafpAA3VsFLaL3MJMQIGXAZ-roUqUDk',
  authDomain: 'academondo.com',
  databaseURL:
    'https://academondo-demo-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'academondo-demo',
  storageBucket: 'academondo-demo.appspot.com',
  messagingSenderId: '922723141609',
  appId: '1:922723141609:web:f59bbf509a384818146d78',
};
const parseDomain = 'http://localhost:1337';
const parse = {
  serverURL: `${parseDomain}/parse`,
};
const functionsBaseUrl = `https://${region}-${firebase.projectId}.cloudfunctions.net/`;
export const environment = {
  region: region,
  production: false,
  serviceWorker: 'ngsw-worker.js',
  stripeKey: 'pk_test_KtxHbJxtqkRYQZDiDRNqqiAp',
  firebase: firebase,
  parse: parse,
  googleMapsApiKey: 'AIzaSyD_rwXyADXPZNvwLbSCC5W1fUcO0slxcYw',
  AGMAPIKEY: 'AIzaSyDhgnpBnuPVBPFeLXWWZL82z7KACK--L3s',
  academondoIdAtfenerum: '68504844-ccaa-4e50-9376-d26f53156cd1',
  fenerumStripeCardUrl: 'https://app.fenerum.com/billing/create_card/stripe/',
  sendEmail: 'sendEmail',
  sendInviteEmail: 'sendInviteEmail',
  checkAndPublishAllJobsNormal: 'checkAndPublishAllJobsNormal',
  checkAndPublishAllCVsNormal: 'checkAndPublishAllCVsNormal',
  checkAndPublishAllSitesNormal: 'checkAndPublishAllSitesNormal',
  getPodioItemsNormal: 'getPodioItemsNormal',
  baseFunctionsUrl: functionsBaseUrl,
  academondoUrl: 'https://demo.academondo.com',
  paymentHandler: 'paymentHandler',
  search: 'search',
  userAdministrationHandler: 'userAdministrationHandler',
  handleNetworkMemberAction: 'handleNetworkMemberAction',
  searchHandler: 'searchHandler',
  membershipHandler: 'membershipHandler',
  attendanceHandler: 'attendanceHandler',
  notificationHandler: 'notificationHandler',
  // authHandler: 'authHandler',
  requestAccessToken: 'requestAccessToken',
  requestAuthCode: 'requestAuthCode',
  requestUserInfo: 'requestUserInfo',
  ssoHandler: 'ssoHandler',

  solrMembers: `membersSearchDemo`,
  solrBillboard: `billboardSearchDemo`,
  solrNetwork: `networkSearchDemo`,
  solrCv: `cvSearchDemo`,
  solrSite: `siteSearchDemo`,
  solrV2: `search-demo`,

  // podio: {
  //   links: [
  //     {
  //       link: 'https://workflow-automation.podio.com/podiofeed.php?c=13654&a=472655&f=5818',
  //       initPath: DataConstants.PODIO_COMPANY,
  //     },
  //   ],
  // },

  auth: {
    // issuer: 'https://auths.tobiaswaagefeldballe.dk/auth/realms/hbp',
    issuer: 'https://iam.ebrains.eu/auth/realms/hbp',
    realm: 'hbp',
    clientId: 'academondo-demo-1', // The "Auth Code + PKCE" client
    responseType: 'code',
    // redirectUri: window.location.origin + '/login/',
    // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    scope: 'openid profile email roles team group', // Ask offline_access to support refresh token refreshes
    useSilentRefresh: true, // Needed for Code Flow to suggest using iframe-based refreshes
    silentRefreshTimeout: 5000, // For faster testing
    timeoutFactor: 0.25, // For faster testing
    sessionChecksEnabled: true,
    showDebugInformation: true, // Also requires enabling "Verbose" level in devtools
    clearHashAfterLogin: false, // https://github.com/manfredsteyer/angular-oauth2-oidc/issues/457#issuecomment-431807040,
    nonceStateSeparator: 'semicolon', // Real semicolon gets mangled by IdentityServer's URI encoding
  },
};
