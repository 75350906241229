import { acaConfig } from '../src/aca-config';
import { hardcodedValuesDK } from '../src/hardcodedValuesDK';
import { hardcodedValuesENG } from '../src/hardcodedValuesENG';
// const supportedLanguage =
//   navigator.language === 'da' || 'en' ? navigator.language : acaConfig.language;

export let hardcodedValues =
  acaConfig.language === 'da' ? hardcodedValuesDK : hardcodedValuesENG;

export function handleRemoteConfigValues(values: {
  [key: string]: any; //: remoteConfig.Value;
}) {
  Object.entries(values).forEach((val) => {
    changeHardcodedValue(val[0], val[1].asString());
  });
}

function changeHardcodedValue(key: string, value: boolean | Number | string) {
  if (hardcodedValues.hasOwnProperty(key)) {
    hardcodedValues[key] = checkAndUseCorrectType(value);
  } else {
    const result = checkAndUseCorrectType(value);
    console.error('unknown config -', key, ' : ', result);
  }
}

function checkAndUseCorrectType(
  value: boolean | Number | string
): boolean | Number | string {
  if (value === 'true' || value === 'false') {
    return JSON.parse(value);
  }
  if (!isNaN(value as number)) {
    return Number(value);
  }
  if (typeof value === 'string') {
    return value;
  }
}

export const formatText = function (text: string, ...replacement: string[]) {
  return text.replace(/{(\d+)}/g, (match, number) => {
    return typeof replacement[number] !== 'undefined'
      ? replacement[number]
      : match;
  });
};
